  import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../session.service';
import { YapilyService } from '../yapily.service';
import { BankChooserDialog } from './BankChooserDialog'
import { CommonModule, CurrencyPipe} from '@angular/common';

import {FormControl, FormGroup, FormBuilder, FormGroupDirective, NgForm, Validators} from '@angular/forms';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {MatFormField} from '@angular/material/form-field';

import Constants from "../../shared/Constants";
import APIManager from '../../shared/APIManager';

import {MatInputCommifiedDirective} from './MatInputCommifiedDirective';

import { take } from 'rxjs/operators';



export function patchMatFormField() {
  const patchedFormFieldClass = MatFormField.prototype as any;

  patchedFormFieldClass.updateOutlineGapOriginal = MatFormField.prototype.updateOutlineGap;
  MatFormField.prototype.updateOutlineGap = function () {
    this.updateOutlineGapOriginal();
    const container = this._connectionContainerRef.nativeElement;
    const gapEls = container.querySelectorAll('.mat-form-field-outline-gap');
    gapEls.forEach((gp) => {
      const calculatedGapWidth = +gp.style.width.replace('px', '');
      const gapWidth = calculatedGapWidth / 0.75;
      gp.style.width = `${gapWidth}px`;
    });
  };
}

@Component({
  selector: 'app-paymentform',
  templateUrl: './paymentform.component.html',
  styleUrls: ['./paymentform.component.css']
})
export class PaymentformComponent implements OnInit {

  transferForm: FormGroup;

  USE_TOKEN = true;

  // dev
//  BACKEND_URL = "https://192.168.0.150:3001/";
    BACKEND_URL = "https://talkingcucumber.org/";

//  BACKEND_URL = "https://78.141.194.67/";
//  BACKEND_URL = "https://talkingcucumber.info/";


  //BACKEND_URL = "https://ep7b.com/";

  //accountNumControl = new FormControl('', [Validators.required]);
  //accountNameControl = new FormControl('', [Validators.required]);
  //sortCodeControl = new FormControl('', [Validators.required]);


  static epUuid: string;

  isLoading = false;

  allBanks: Observable<any[]>;

  // the stage we are on
  stageIdx = 1;

  // controls auth button
  authButtonDisabled = "false";

  // controls make payment button
  makePaymentsDisabled = "false";

  currencyAmount = 1;

  consentId: string;
  innyId: string;
  epUuid: string;

  sortCodeRegex = /^\d\d\d\d\d\d$/;
  accountNumRegex = /^\d\d\d\d\d\d\d\d$/;

  tkRequestId: string;

formattedAmount;

threeDecPrecisionDecimalMaskOptions = {
  align: 'right',
  allowNegative: false,
  decimalSeparator: '.',
  precision: 3,
  prefix: '',
  suffix: '',
  thousandsSeparator: '',
  valueMode: 'standard',
};


  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router,
    private formBuilder: FormBuilder,
    private currencyPipe : CurrencyPipe,
    public dialog: MatDialog,
    private yapilyService: YapilyService
  ) { }

  async ngOnInit() {
      console.log ("app init");

      console.log ("windowLocation : " + window.location.href );

      localStorage.setItem("access_token", null);

      //this.isLoading = true;

      this.transferForm = this.formBuilder.group({
        bankSelectControl: [{disabled: true}, [Validators.required]],
        accountNumControl: [null, [Validators.required]],
        referenceControl: [null, [Validators.required]],
        accountNameControl: [null, [Validators.required]],
        sortCodeControl: [null, [Validators.required, Validators.pattern(this.sortCodeRegex)]],
        amountControl: [null, [Validators.required]]
      });



      /*
      this.transferForm.controls['amountControl'].valueChanges.subscribe(change => {
        console.log(change);

      });*/

      let queryString = window.location.search;
      console.log ("queryString : " + queryString);

      let urlParams = new URLSearchParams(queryString);

      let consentIdTmp = this.route.snapshot.paramMap.get('consent');
      let innyIdTmp = this.route.snapshot.paramMap.get('institution');

      console.log ("consentIdTmp : " + consentIdTmp);
      console.log ("innyIdTmp : " + innyIdTmp);

      // initial values
      let inAcc = this.route.snapshot.paramMap.get('inacc')
      let inStc = this.route.snapshot.paramMap.get('instc')
      let inAm = this.route.snapshot.paramMap.get('inam')

      let inNm = this.route.snapshot.paramMap.get('innm')

      // https://web.ep7.co.uk?inacc=12345678&instc=123456&innm=JoelB&inam=100

      console.log ("inAcc : " + inAcc);

      this.tkRequestId = this.route.snapshot.paramMap.get('tkRId')
      let tkMessage = this.route.snapshot.paramMap.get('tkM')
      let tkTraceId = this.route.snapshot.paramMap.get('tkTrId')
      let tkError = this.route.snapshot.paramMap.get('tkE')

      // token
      if (this.tkRequestId != null) {

        console.log ("tkError : " + tkError);

        if (tkError == null) {
          console.log ("tokenError is null");
        }

        if (tkError == "null") {
          console.log ("tokenError is null string");
        } else {
          console.log ("tokenError...!");

          // show message here
          return;
        }

      }

      if (inAcc != null) {
        console.log ("gotInAcc");
        this.transferForm.controls['accountNumControl'].setValue(inAcc);
        this.transferForm.controls['accountNumControl'].disable();
      }

      if (inStc != null) {
        console.log ("gotInStc");
        this.transferForm.controls['sortCodeControl'].setValue(inStc);
        this.transferForm.controls['sortCodeControl'].disable();
      }

      if (inAm != null) {
        console.log ("gotInAmc");
        let amountNice = this.currencyPipe.transform(inAm, '£');
        this.transferForm.controls['amountControl'].setValue(amountNice);
        this.transferForm.controls['amountControl'].disable();
      }

      if (inNm != null) {
        console.log ("gotInAmc");
        this.transferForm.controls['accountNameControl'].setValue(inNm);
        this.transferForm.controls['accountNameControl'].disable();
      }


      var bankSelectedInc = localStorage.getItem ("bankSelected")

      if (this.USE_TOKEN) {
        bankSelectedInc = "tokenBank";
      }

      console.log ("bankSelectedInc : " + bankSelectedInc);


      if ((bankSelectedInc != undefined) && (bankSelectedInc != "")) {

        let incAccountNum = localStorage.getItem ("accountNameControl");
        console.log ("incAccountNum : " + incAccountNum);

        this.transferForm.controls['bankSelectControl'].setValue(bankSelectedInc);
        this.transferForm.controls['accountNameControl'].setValue(localStorage.getItem ("accountName"));
        this.transferForm.controls['accountNameControl'].disable();

        let amountStr = localStorage.getItem ("amount");
        let amountNice = this.currencyPipe.transform(amountStr, '£');
        this.transferForm.controls['amountControl'].setValue(amountNice);

        this.transferForm.controls['amountControl'].disable();



        this.transferForm.controls['sortCodeControl'].setValue(localStorage.getItem ("sortCode"));
        this.transferForm.controls['sortCodeControl'].disable();

        this.transferForm.controls['accountNumControl'].setValue(localStorage.getItem ("accountNum"));
        this.transferForm.controls['accountNumControl'].disable();

        this.transferForm.controls['referenceControl'].setValue(localStorage.getItem ("reference"));

      }

      //this.transferForm.controls['accountNameControl'].setValue("accountName");

      if (consentIdTmp == null) {
        console.log ("consentId is not available");
      } else {
        this.consentId = consentIdTmp;
        this.innyId = innyIdTmp;

        this.stageIdx = 2

        let epUuidTmp = localStorage.getItem ("EpUuid");

        this.epUuid = epUuidTmp;
        console.log ("epUuidTmp : " + epUuidTmp);

      }

      // qr code payments
      let fullName = this.route.snapshot.paramMap.get('fullName');

      if (fullName != undefined) {

        let prefixCode = this.route.snapshot.paramMap.get('prefixCode');
        let shortName = this.route.snapshot.paramMap.get('shortName');
        let branchId = this.route.snapshot.paramMap.get('branchId');
        let accountName = this.route.snapshot.paramMap.get('accountName');
        let accountNum = this.route.snapshot.paramMap.get('accountNum');
        let sortCode = this.route.snapshot.paramMap.get('sortCode');
        let emailsToNoti = this.route.snapshot.paramMap.get('emailsToNoti');
        let amountToPay = this.route.snapshot.paramMap.get('amountToPay');
        let cashCheStr = this.route.snapshot.paramMap.get('cashCheStr');

        let paymentId = this.route.snapshot.paramMap.get('paymentId');
        let uuIdUse = this.route.snapshot.paramMap.get('uuIdUse');

        let customerAccountNum = this.route.snapshot.paramMap.get('customerAccountNum');



        console.log ("fullName : " + fullName);
        console.log ("prefixCode : " + prefixCode);
        console.log ("shortName : " + fullName);
        console.log ("branchId : " + branchId);
        console.log ("accountName : " + accountName);
        console.log ("accountNum : " + accountNum);
        console.log ("sortCode : " + sortCode);
        console.log ("emailsToNoti : " + emailsToNoti);
        console.log ("amountToPay : " + amountToPay);
        console.log ("cashCheStr : " + cashCheStr);
        console.log ("cashCheStr : " + cashCheStr);
        console.log ("paymentId : " + paymentId);
        console.log ("uuIdUse : " + uuIdUse);

        localStorage.setItem("emailsToNoti", emailsToNoti);

        var referenceUse = branchId.substring(0, 2) + " EP" + customerAccountNum + " " + cashCheStr

        localStorage.setItem("paymentId", paymentId + "");
        localStorage.setItem("uuIdUse", uuIdUse + "");

        this.transferForm.controls['accountNumControl'].setValue(accountNum);
        this.transferForm.controls['accountNumControl'].disable();

        this.transferForm.controls['sortCodeControl'].setValue(sortCode);
        this.transferForm.controls['sortCodeControl'].disable();

        let amountNice = this.currencyPipe.transform(amountToPay, '£');
        this.transferForm.controls['amountControl'].setValue(amountNice);
        this.transferForm.controls['amountControl'].disable();

        this.transferForm.controls['accountNameControl'].setValue(accountName);
        this.transferForm.controls['accountNameControl'].disable();

        this.transferForm.controls['referenceControl'].setValue(referenceUse);
      }

      if (!this.USE_TOKEN) {
        this.getBanksWithAuthToken(0);
      }


      let shownFCAWarning = localStorage.getItem ("shownFCAWarning")

      if ((shownFCAWarning == null) || (shownFCAWarning == undefined) || (shownFCAWarning == "false")) {

        // don't show FCA warning with token
        if (!this.USE_TOKEN) {
          localStorage.setItem("shownFCAWarning", "true");

          const dialogRef = this.dialog.open(FCAWarningDialog, {
            width: '420px',
            height: '400px',
            //autoFocus: false,
            data: {}
          });
        }
      }

/*
      const dialogRef = this.dialog.open(BankChooserDialog, {
        width: '500px',
        height: '400px',
      });
*/
      this.transferForm.controls['bankSelectControl'].disable();

      if (this.tkRequestId != null) {
        console.log ("dealingWithToken");

        this.checkTokenPayment ();
      }

  }


  openDialog() {
    const dialog = this.dialog.open(BankChooserDialog,
      {
        width: '350px',
        height: '400px',
      });

    dialog.afterClosed().subscribe(() => {
        console.log ("bank chooser closed...!");

        let bankSelectedInc = localStorage.getItem ("bankSelected");

        console.log ("bankSelectedInc : " + bankSelectedInc);

        this.transferForm.controls['bankSelectControl'].setValue(bankSelectedInc);

    });

  }

  submit() {

    if (this.USE_TOKEN) {
      this.submitWithToken ();
      return;
    }

    console.log ("submit : " + this.transferForm.valid);

    if (!this.transferForm.valid) {
      return;
    }

    if (this.authButtonDisabled == "true") {
      return;
    }

    this.authButtonDisabled = "true";

    this.transferForm.controls['bankSelectControl'].enable();
    this.transferForm.controls['amountControl'].enable();
    this.transferForm.controls['accountNameControl'].enable();
    this.transferForm.controls['sortCodeControl'].enable();
    this.transferForm.controls['accountNumControl'].enable();

    console.log(this.transferForm.value ["bankSelectControl"]);

    let bankSelected = this.transferForm.value ["bankSelectControl"]
    let accountNum = this.transferForm.value ["accountNumControl"]
    let accountName = this.transferForm.value ["accountNameControl"]
    let sortCode = this.transferForm.value ["sortCodeControl"]
    let amount = this.transferForm.value ["amountControl"].replace("£", "").replace(",", "")

    let reference = this.transferForm.value ["referenceControl"]

    console.log ("bankSelected : " + bankSelected);
    console.log ("accountNum : " + accountNum);
    console.log ("accountName : " + accountName);
    console.log ("sortCode : " + sortCode);
    console.log ("amount : " + amount);

    localStorage.setItem("bankSelected", bankSelected + "");
    localStorage.setItem("accountNum", accountNum + "");
    localStorage.setItem("accountName", accountName + "");
    localStorage.setItem("amount", amount + "");
    localStorage.setItem("sortCode", sortCode + "");
    localStorage.setItem("reference", reference + "");

    console.log ("off to payment : bankSelected : " + bankSelected);

    this.getPaymentAuthWithAuthToken (bankSelected, accountName, accountNum, amount, sortCode, reference, 0);

  }

  async submitWithToken () {

    console.log ("submit : " + this.transferForm.valid);

    if (!this.transferForm.valid) {
      return;
    }

    if (this.authButtonDisabled == "true") {
      return;
    }

    this.authButtonDisabled = "true";

    this.transferForm.controls['amountControl'].enable();
    this.transferForm.controls['accountNameControl'].enable();
    this.transferForm.controls['sortCodeControl'].enable();
    this.transferForm.controls['accountNumControl'].enable();

    console.log(this.transferForm.value ["bankSelectControl"]);

    let accountNum = this.transferForm.value ["accountNumControl"]
    let accountName = this.transferForm.value ["accountNameControl"]
    let sortCode = this.transferForm.value ["sortCodeControl"]
    let amount = this.transferForm.value ["amountControl"].replace("£", "").replace(",", "")

    let reference = this.transferForm.value ["referenceControl"]

    console.log ("accountNum : " + accountNum);
    console.log ("accountName : " + accountName);
    console.log ("sortCode : " + sortCode);
    console.log ("amount : " + amount);

    localStorage.setItem("accountNum", accountNum + "");
    localStorage.setItem("accountName", accountName + "");
    localStorage.setItem("amount", amount + "");
    localStorage.setItem("sortCode", sortCode + "");
    localStorage.setItem("reference", reference + "");

    console.log ("off to payment");

    this.isLoading = true;

    await this.makeTokenRequestWithAuthToken (amount, sortCode, accountNum, accountName, reference, 0);

  }

  onChangeBank () {
    console.log ("onChangeBank");
  }


  async resetForm () {
    console.log ("resetForm");

    // only for testing
    //this.sendEmailNotification ("joel@talkingcucumber.com", "complete", "recip", "10", "refstr");

/*
    const dialogRef = this.dialog.open(PaymentCompleteDialog, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
           console.log ("sending notification");
           this.router.navigate(['/thankyou', {
           }]);
        });*/


    this.stageIdx = 1;

    localStorage.setItem("bankSelected", "");
    localStorage.setItem("accountNum", "");
    localStorage.setItem("accountName", "");
    localStorage.setItem("amount", "");
    localStorage.setItem("sortCode", "");
    localStorage.setItem("emailsToNoti", "");
    localStorage.setItem("reference", "");

    localStorage.setItem("shownFCAWarning", "false");

    this.transferForm.controls['bankSelectControl'].setValue("");
    this.transferForm.controls['accountNameControl'].setValue("");
    this.transferForm.controls['amountControl'].setValue("");
    this.transferForm.controls['sortCodeControl'].setValue("");
    this.transferForm.controls['accountNumControl'].setValue("");
    this.transferForm.controls['referenceControl'].setValue("");

    this.consentId = null;
    this.innyId = null;
    this.epUuid = null;

  }

  transformAmount(element){

        console.log ("element : " + element.target.value);

        let tmpAmount = this.currencyPipe.transform(element.target.value.replace("£", "").replace(",", ""), '£');
        console.log ("tmpAmount : " + tmpAmount);

        element.target.value = tmpAmount;
    }

  async checkTokenPayment () {

    //let requestId = "rq:47zj89PZKMz13CwpkLDUsTFCpcYy:5zKtXEAq";
    let requestId = this.tkRequestId;

    await this.callCheckTokenRequest (requestId, 0, (tryNumberGot, requestStatus) => {

      console.log("gaveUpWaiting");
    });



  }


  async callCheckTokenRequest (requestId, tryNumber, callback) {

    let currentTry = tryNumber + 1;

    console.log ("currentTry : " + currentTry);

    let emailsShouldNoti = localStorage.getItem ("emailsToNoti")
    let accountName = localStorage.getItem ("accountName")

    console.log ("accountName : " + accountName);

    let amountStr = localStorage.getItem ("amount");
    let amount = this.currencyPipe.transform(amountStr, '£');
    let reference = localStorage.getItem ("reference")



    var invoiceRef = reference
    let emailsToNoti = emailsShouldNoti;

    console.log ("emailsToNoti : " + emailsToNoti);

    if (tryNumber < 10) {

      this.isLoading = true;

      await this.getTokenRequestResultWithAuthToken (requestId, 0, (moreJsonData, morePaymentStatus) => {

          console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
          console.log ("morePaymentStatus : " + morePaymentStatus);

          let statusCode = moreJsonData.statusCode

          this.isLoading = false

          if (statusCode == "200") {
            let tokenStatus = moreJsonData.tokenStatus

            console.log ("tokenStatus : " + tokenStatus);

            var shouldRetry = false;

            let paymentId = localStorage.getItem ("paymentId")
            let uuIdUse = localStorage.getItem ("uuIdUse")

            let paymentRef = this.transferForm.value ["referenceControl"];

            console.log ("tokenRequest : paymentId : " + paymentId + ", uuIdUse : " + uuIdUse);

            if ((tokenStatus == "PENDING") || (tokenStatus == "PROCESSING"))  {
              shouldRetry = true;
            }

            if (tokenStatus == "EXPIRED") {
            //if (tokenStatus == "PROCESSED") {

              const dialogRef = this.dialog.open(PaymentFailedDialog, {
                width: '250px',
                data: {}
              });

              callback (currentTry, tokenStatus);
              return;
            }

            if (tokenStatus == "PROCESSED") {

              // this needs re-enabling
              //this.sendEmailNotification (emailsToNoti, tokenStatus, accountName, amount, invoiceRef)

              this.signInToFirebaseAgain (uuIdUse, paymentId, paymentRef);

              const dialogRef = this.dialog.open(PaymentCompleteDialog, {
                width: '250px',
                data: {}
              });

              this.resetForm ();

              dialogRef.afterClosed().subscribe(result => {
                 console.log ("sending notification");
                 callback ("ok");
                 this.router.navigate(['/thankyou', {
                 }]);
              });

              return;
            }

            if (shouldRetry) {
              let retryTimer = setTimeout(
                () => {
                console.log ("retryTimerFired");

                this.callCheckTokenRequest (requestId, currentTry, (currentTryGot, requestStatus) => {


                  console.log("gaveUpWaiting" + currentTry);
                });

               },
                10000
              )
            }

            callback (currentTry, tokenStatus);

          } else {
            callback (101, "ERROR");
          }


      });

    } else {
      callback (101, "GAVE UP");
    }




  }

  async fakeQRPressed () {
    console.log ("fakeQRPressed");


    let branchName = "EchoPay Merchant";

    /*
    var dataUse = {
        "accountNum" : "12345678",
        "accountNumber" : "12345678",
        "amountPay" : "0.0",
        "amount" : "0.0",
        "customerName" : "EchoPay Merchant",
        "accountName" : "EchoPay Merchant Account",
        "destEmail" : "joel@talkingcucumber.com",
        "destSortCode" : "090222",
        "destAccountNumber" : "10127829",
        "paymentRef" : "3Q EP12345678 ",
        "userId" : "0HdM4SVl6jdczZSI1tfuPWomY7g2",
        "userPaymentUid" : "0e1836fd-292c-9929-7d21",

        "status" : "completed",
        "uuid" : "0HdM4SVl6jdczZSI1tfuPWomY7g2",
        "merchantId" : "3Q9426844B9X1",
        "depotRef" : "EchoPay Merchant",
        "destAccountName" : "EchoPay Merchant Account",
        "destAccountNum" : "10127829",
      }

    await this.makePaymentEntryBackendSnap (dataUse, (jsonData, paymentStatus) => {
    });
*/



/*
    this.getAuthToken ((status, accessToken) => {
      console.log ("status : " + status);
      console.log ("accessToken : " + accessToken);
    });
*/
    /*

    let consentId = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJJTlNUSVRVVElPTiI6InlhcGlseS1tb2NrIiwiQ09OU0VOVCI6IjFiZjQ4NGExLWJjNzEtNDhmMS04MzA0LTZlODRiNTZlZjFkOSIsIkFQUExJQ0FUSU9OX1VTRVJfSUQiOiJFQ0hPMDEiLCJVU0VSIjoiYjlkZWE4OWQtYzNmZS00NDZkLWIzYmMtYTRlNjFhZTA2ZDA5In0.nqCM1zaIZgSZCpefij0M3iE-7SGpxDlDZku8C4ulk4pO5VcWbJzyRnmRo2Xz02A2gX0kaZatTE90UIwy1KxnZA";
    let paymentId = "58923-003";

    this.getPaymentStatus(consentId, paymentId);
    */

    /*
    let echoPosQRCode = "https://web.ep7.co.uk/?insr=echopos&inan=11223344&inuu=VMZFgADH5TgX9bhKLa8ffVGJ8BR2&inam=11&inpi=7da6db60-8450-11ec-bd29-e3b30a68d220";
*/

  //https://web.ep7.co.uk/?insr=echocloud&inan=12345678&inuu=De4ZEoyvfkUZ3L0ucjcj6qVNeqx1&inam=11.11&inpi=0e1836fd-292c-9929-7d21

//https://web.ep7.co.uk/?insr=echocloud&inan=12345678&inuu=De4ZEoyvfkUZ3L0ucjcj6qVNeqx1&inam=11.11&inpi=5f0917eb-5e25-5ea7-52c6

    //https://192.168.0.150/?insr=echocloud&inan=12345678&inuu=De4ZEoyvfkUZ3L0ucjcj6qVNeqx1&inam=0.01&inpi=30734e1f-ff0c-d870-95f7

    this.router.navigate(['/confirmbranch', {
      "userUid": "De4ZEoyvfkUZ3L0ucjcj6qVNeqx1",
      "accountNum": "12345678",
      "paymentUid": "30734e1f-ff0c-d870-95f7",
      "amount":  "25.93",
      }]);

/*
    this.router.navigate(['/confirmbranch', {
//      "siteRef": "Head Office Branch",
      "siteRef": "EchoPay Merchant",
//      EchoPay Merchant
      "accountNum": "12345678",
      "invoiceNumber": "invoiceNumber",
      "amount":  "9.99",
      "echoPosPaymentId":"echoPosPaymentId"
      }]);
      */
  }


  async sendEmailNotification (emailAddress, status, recip, amount, refstr) {
    console.log ("emailAddress : " + emailAddress);

    var queryStr = "dest=" + emailAddress;
    queryStr = queryStr + "&recip=" + recip;
    queryStr = queryStr + "&amount=" + amount;
    queryStr = queryStr + "&status=" + status;
    queryStr = queryStr + "&refstr=" + refstr;

    let url = "https://us-central1-echopay-c70b9.cloudfunctions.net/emailSender?" + queryStr;

    try {

          let response = await fetch(url,
              {
              method: 'GET',
              mode: 'no-cors'
              }
            );

          console.log ("response : " + JSON.stringify(response));

          let responseBlob = await response.blob();

          console.log("responseBlob : " + JSON.stringify(responseBlob));

          const reader = new FileReader();

          reader.addEventListener('loadend', (e) => {

            console.log ("reader.result : " + reader.result);

            return null;
          });

          reader.readAsText(responseBlob);

        } catch (error) {

          console.log ("send email error : " + error);
        }

  }

  async getAuthToken (callback) {

    console.log ("getAuthToken");

    let backendUrl = this.BACKEND_URL + "getAuthToken?clientSecret=IZZYSEKKER2022";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "Content-Type" : "application/json"
    };

        try {

                let response = await fetch(backendUrl,
                    {
                    method: 'GET',
                    headers: headers,
                    }
                  );

                console.log ("response : " + JSON.stringify(response));

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;

                  try {

                    console.log ("reader.result : " + reader.result);

                    let jsonData = JSON.parse (reader.result  as string);
                    console.log ("jsonData : " + jsonData)

                    if (jsonData.status == "isOK") {

                      let accessToken = jsonData.access_token;

                      console.log ("accessToken : " + accessToken);

                      callback (jsonData.status, accessToken);

                    } else {
                      callback (jsonData.status, "");
                    }


                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);
                    alert ("The following error occured while getting an AuthToken. Please try again\n\njsonParseError : " + e);
                    //callback(e, []);
                    return;
                  }

                  //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                  return null;
                });

                reader.readAsText(responseBlob);

              } catch (error) {

                callback ("failed", "");

                console.log ("getMe error : " + error);

                //return error

              }

  }

  async getPaymentAuthWithAuthToken (bankSelected, accountName, accountNum, amount, sortCode, reference, currentTry) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("accessToken : " + accessToken);

    if (accessToken == null) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);

          this.getPaymentAuthWithAuthToken (bankSelected, accountName, accountNum, amount, sortCode, reference, 1);
        }
      });
    } else {
      this.getPaymentAuth (bankSelected, accountName, accountNum, amount, sortCode, reference, (paymentGetStatus) => {
        console.log ("paymentGetStatus : " + paymentGetStatus);

        if (currentTry == 1) {
          alert ("Could not get access token after second try...!, Error Code : # 1002");
          return;
        }

        if (paymentGetStatus == "invalidToken") {
          this.getPaymentAuthWithAuthToken (bankSelected, accountName, accountNum, amount, sortCode, reference, 1);
        }
      })
    }
  }


  async getPaymentAuth (bankSelected, accountName, accountNum, amount, sortCode, reference, callback) {

    console.log ("getPaymentAuth");

    console.log ("bankSelected : " + bankSelected);

    var backendUrl = this.BACKEND_URL + "paymentauth?";
    backendUrl = backendUrl + "id=" + bankSelected + "&";
    backendUrl = backendUrl + "nm=" + accountName + "&";
    backendUrl = backendUrl + "an=" + accountNum + "&";
    backendUrl = backendUrl + "am=" + amount + "&";
    backendUrl = backendUrl + "rf=" + reference + "&";
    backendUrl = backendUrl + "sc=" + sortCode;

    console.log ("backendUrl : " + backendUrl);

    let accessToken = localStorage.getItem ("access_token");

    console.log ("accessToken : " + accessToken);


    let headers = {
      "x-access-token" : "" + accessToken
    };

    try {

      let response = await fetch(backendUrl,
          {
          method: 'GET',
          headers: headers,
          }
        );

      console.log ("response : " + JSON.stringify(response));

      let responseBlob = await response.blob();

      console.log("responseBlob : " + JSON.stringify(responseBlob));

      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {

        //var responseJSON = ;

        try {

          console.log ("reader.result : " + reader.result);

          if (reader.result == "invalid token...") {
            callback ("invalidToken");
            return;
          }

          let jsonData = JSON.parse (reader.result  as string);

          console.log ("jsonData : " + jsonData)

          let errorJson = jsonData ["errorJson"];

          if (errorJson != undefined) {
            console.log ("errorJson : " + errorJson)

            let errorJsonData = JSON.parse (errorJson);
            console.log ("errorJsonData : " + errorJsonData)

            var errorMsg = "GetPaymentAuth failed with : \n\n";

            errorMsg = errorMsg + errorJsonData ["error"]["code"] + " " + errorJsonData ["error"]["status"] + " " + errorJsonData ["error"]["message"];
            errorMsg = errorMsg + "\n\n" + errorJsonData ["error"]["tracingId"];

            alert (errorMsg);

          } else {

            let callBackUrl = jsonData.data.authorisationUrl;
            let epUuid = jsonData.epUuid;

            localStorage.setItem("EpUuid", epUuid + "");

            console.log ("epUuid : " + epUuid)

            this.yapilyService.setEPUuid (epUuid + "");

            callback ("ok");

            console.log ("callBackUrl : " + callBackUrl);
            window.location.href = callBackUrl;

            //responseJSON = JSON.parse (reader.result)

          }



        } catch (e) {
          console.log ("jsonParseError : " + e);

          callback ("error");

          alert ("The following error occured. Please try again\n\njsonParseError : " + e);
          //callback(e, []);
          return;
        }

        return null;
      });

      reader.readAsText(responseBlob);

    } catch (error) {
      console.log ("getMe error : " + error);
    }

  }


  async getMakePaymentWithAuth (currentTry) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("accessToken : " + accessToken);

    if (this.makePaymentsDisabled == "true") {
      return;
    }

    this.makePaymentsDisabled = "true"

    if (accessToken == null) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);

          this.getMakePaymentWithAuth (1);
        }
      });
    } else {
      this.getMakePayment ((makePaymentStatus) => {
        console.log ("makePaymentStatus : " + makePaymentStatus);

        if (currentTry == 1) {
          alert ("Could not get access token after second try...!, Error Code : # 1003");
          return;
        }

        if (makePaymentStatus == "invalidToken") {
          this.getMakePaymentWithAuth (1);
        }


      })
    }

  }

  async getMakePayment (callback) {

    console.log ("consentId : " + this.consentId);

    this.isLoading = true;

    this.transferForm.controls ["amountControl"].enable();
    this.transferForm.controls ["accountNameControl"].enable();
    this.transferForm.controls ["sortCodeControl"].enable();
    this.transferForm.controls ["accountNumControl"].enable();

    let bankSelected = this.transferForm.value ["bankSelectControl"]
    let accountNum = this.transferForm.value ["accountNumControl"]
    let accountName = this.transferForm.value ["accountNameControl"]
    let sortCode = this.transferForm.value ["sortCodeControl"]
    let amount = this.transferForm.value ["amountControl"].replace("£", "").replace(",", "")
    let reference = this.transferForm.value ["referenceControl"]

    let emailsShouldNoti = localStorage.getItem ("emailsToNoti")

    console.log ("emailsShouldNoti : " + emailsShouldNoti);

    let emailsToNoti = emailsShouldNoti;

    //let emailsToNoti = "joel@talkingcucumber.com"

    console.log ("emailsShouldNoti : " + emailsShouldNoti);

    //var invoiceRef =  accountName.substring(0, 2).toUpperCase() + " EP" + accountNum;

    var invoiceRef = reference

    console.log ("bankSelected : " + bankSelected);
    console.log ("accountNum : " + accountNum);
    console.log ("accountName : " + accountName);
    console.log ("sortCode : " + sortCode);
    console.log ("amount : " + amount);
    console.log ("reference : " + reference);

    var backendUrl = this.BACKEND_URL + "makePaymentRequest?consentId=" + this.consentId + "&innyId=" + this.innyId + "&epUuid=" + this.epUuid + "&";

    backendUrl = backendUrl + "id=" + bankSelected + "&";
    backendUrl = backendUrl + "nm=" + accountName + "&";
    backendUrl = backendUrl + "an=" + accountNum + "&";
    backendUrl = backendUrl + "am=" + amount + "&";
    backendUrl = backendUrl + "rf=" + reference + "&";
    backendUrl = backendUrl + "sc=" + sortCode;

    //makePaymentRequest?consentId=12

    console.log ("backendUrl : " + backendUrl);

    let accessToken = localStorage.getItem ("access_token");

    let headers = {
      "x-access-token" : accessToken
    };

    try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,
            }
          );

          console.log ("response : " + JSON.stringify(response));

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;
                  this.isLoading = false;

                  try {

                    console.log ("reader.result : " + reader.result);

                    let jsonData = JSON.parse (reader.result  as string);

                    let errorJson = jsonData ["errorJson"];

                    if (errorJson != undefined) {
                      console.log ("errorJson : " + errorJson)

                      let errorJsonData = JSON.parse (errorJson);
                      console.log ("errorJsonData : " + errorJsonData)

                      var errorMsg = "GetPaymentAuth failed with : \n\n";

                      errorMsg = errorMsg + errorJsonData ["error"]["code"] + " " + errorJsonData ["error"]["status"] + " " + errorJsonData ["error"]["message"];
                      errorMsg = errorMsg + "\n\n" + errorJsonData ["error"]["tracingId"];

                      alert (errorMsg);

                    } else {


                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }


                    console.log ("jsonData : " + jsonData)

                    //let paymentStatus = jsonData.data.status;
                    let paymentStatus = "PENDING";

                    let paymentIdYap = jsonData.data.id;

                    console.log ("paymentStatus : " + paymentStatus);
                    console.log ("paymentIdYap : " + paymentIdYap);
                    console.log ("this.consentId : " + this.consentId);

                    if (paymentStatus == "COMPLETED") {

                      let paymentRef = this.transferForm.value ["referenceControl"];

                      let paymentId = localStorage.getItem ("paymentId")
                      let uuIdUse = localStorage.getItem ("uuIdUse")

                      console.log ("paymentId : " + paymentId);
                      console.log ("uuIdUse : " + uuIdUse);

                      this.signInToFirebaseAgain (uuIdUse, paymentId, paymentRef);

                      console.log ("emailsToNotiComp : " + emailsToNoti);

                      // this needs to be enabled
                      //this.sendEmailNotification (emailsToNoti, paymentStatus, accountName, amount, invoiceRef)

                      const dialogRef = this.dialog.open(PaymentCompleteDialog, {
                        width: '250px',
                        data: {}
                      });

                      this.resetForm ();

                      dialogRef.afterClosed().subscribe(result => {
                         console.log ("sending notification");
                         callback ("ok");
                         this.router.navigate(['/thankyou', {
                         }]);
                      });


                    } else {
                      //alert ("Payment has status : " + paymentStatus)

                      // not all banks process payment immediately...

                      this.isLoading = true

                      console.log ("paymentIdYap : " + paymentIdYap);
                      console.log ("paymentIdYap : " + paymentIdYap);

                      this.getPaymentStatusWithAuthToken(this.consentId, paymentIdYap, 0);

                      /*
                      const dialogRef = this.dialog.open(PaymentFailedDialog, {
                        width: '250px',
                        data: {}


                      });
                      */

                      console.log ("paymentStatus : " + paymentStatus);
                      callback ("ok");
                    }
                  }

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                    callback ("error");
                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }

  }


  async getBanksWithAuthToken (currentTry) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("gbwat : accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("gbwat : accessToken is null");
    } else {
      console.log ("gbwat : accessToken is not null");
    }




    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("gbwat : status : " + status);
        console.log ("gbwat : accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);

          this.getBanksWithAuthToken (1);
        }
      });
    } else {
      this.getBanks ((bankGetStatus) => {
        console.log ("gbwatA : bankGetStatus : " + bankGetStatus);

        if (bankGetStatus.includes ("Error : ")) {
          alert (bankGetStatus);
          return;
        }

        if (bankGetStatus != "ok") {
          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1004");
            return;
          }

          if (bankGetStatus == "invalidToken") {
            this.getBanksWithAuthToken (1);
          }
        }

        return;




      })
    }

  }

  async getBanks (callback) {

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "banks";
    console.log ("gb : backendUrl : " + backendUrl);
    console.log ("gb : accessToken : " + accessToken);

    let headers = {
      "x-access-token" : accessToken
    };

    try {



            let response = await fetch(backendUrl,
                {
                method: 'GET',
                headers: headers,

                }
              );

            console.log ("response : " + JSON.stringify(response));

            let responseBlob = await response.blob();

            console.log("responseBlob : " + JSON.stringify(responseBlob));

            const reader = new FileReader();

            reader.addEventListener('loadend', (e) => {

              //var responseJSON = ;

              try {

                console.log ("reader.result : " + reader.result);

                if (reader.result == "invalid token...") {
                  callback ("invalidToken");
                  return;
                }


                let jsonData = JSON.parse (reader.result  as string);

                if (jsonData ["message"] != undefined) {
                  console.log ("got error : " + jsonData ["message"]);
                  callback ("Error : " + jsonData ["message"]);
                  return;
                }

                this.allBanks = jsonData;

                console.log ("jsonData : " + jsonData)

                callback ("ok");

                //responseJSON = JSON.parse (reader.result)
              } catch (e) {
                console.log ("jsonParseError : " + e);
                callback ("error");

                alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                //callback(e, []);
                return;
              }

              //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

              return null;
            });

            reader.readAsText(responseBlob);

          } catch (error) {


            console.log ("getMe error : " + error);

            //return error

          }

  }




  async gotoQRCodeScanner () {
  }

  async signInToFirebaseAgain (userUid, paymentUid, paymentRef) {

      /*
      let email = "joel@tc.com";
      let password = "Password1234";

      this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {

          console.log ("login success...! py");

          //this.updatePaymentInFirebase (userUid, paymentUid);
          this.getPaymentDetailsFromFirebaseSnap (paymentUid, userUid, paymentRef);

      }).catch((error) => {
        console.log ("error : " + error.message);
        window.alert(error.message)
      })*/

      await this.getPaymentDetailsFromBackendSnap (paymentUid, userUid, async (jsonData, paymentStatus) =>  {

        console.log ("jsonData : " + JSON.stringify(jsonData));
        console.log ("paymentStatus : " + paymentStatus);

        if (paymentStatus != "ok") {
          alert ("Could not find payment " + paymentUid + " for user " + userUid);
          return;
        }

        let custAccountNumber = jsonData ["accountNumber"];
        let amount = jsonData ["amountPay"];
        let branchId = jsonData ["customerId"];
        let branchName = jsonData ["customerName"];
        let accountName = jsonData ["destAccountName"];
        let accountNum = jsonData ["destAccountNum"];
        let emailsToNoti = jsonData ["destEmail"];
        let sortCode = jsonData ["destSortCode"];

        console.log ("accountName : " + accountName);
        console.log ("branchName : " + branchName);
        console.log ("accountNum : " + accountNum);
        console.log ("sortCode : " + sortCode);
        console.log ("emailsToNoti : " + emailsToNoti);

        console.log ("accountName : " + accountName);
        console.log ("branchName : " + branchName);
        console.log ("accountNum : " + accountNum);
        console.log ("sortCode : " + sortCode);
        console.log ("emailsToNoti : " + emailsToNoti);


        console.log ("paymentRef : " + paymentRef);

        let paymentDetails = {
          "accountNum" : custAccountNumber,
          "accountNumber" : custAccountNumber,
          "amountPay" : amount,
          "amount" : amount,
          "customerName" : branchName,
          "accountName" : accountName,
          "destEmail" : emailsToNoti,
          "destSortCode" : sortCode,
          "destAccountNumber" : accountNum,
          "paymentRef" : paymentRef,

          "userId" : userUid,
          "userPaymentUid" : paymentUid,

          "status" : "completed",
          "uuid" : userUid,
          "merchantId" : branchId,
          "depotRef" : branchName,
          "destAccountName" : accountName,
          "destAccountNum" : accountNum,
        }

        console.log ("amount : " + amount);
        console.log ("custAccountNumber : " + custAccountNumber);

        await this.makePaymentEntryBackendSnap (paymentDetails, (moreJsonData, morePaymentStatus) => {

          console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
          console.log ("morePaymentStatus : " + morePaymentStatus);

        });

      });

    }









      async getPaymentStatusWithAuthToken (consentId, paymentId, currentTry) {

        let accessToken = localStorage.getItem ("access_token")

            console.log ("accessToken : " + accessToken);

            if (accessToken == null) {

              this.getAuthToken ((status, accessToken) => {
                console.log ("status : " + status);
                console.log ("accessToken : " + accessToken);

                if (status == "isOK") {
                  localStorage.setItem("access_token", accessToken);

                  this.getPaymentStatusWithAuthToken (consentId, paymentId, 1);
                }
              });
            } else {
              this.getPaymentStatus (consentId, paymentId, (bankGetStatus) => {
                console.log ("bankGetStatus : " + bankGetStatus);

                if (currentTry == 1) {
                  alert ("Could not get access token after second try...!, , Error Code : # 1006");
                  return;
                }

                if (bankGetStatus == "invalidToken") {
                  this.getPaymentStatusWithAuthToken (consentId, paymentId, 1);
                }


              })
            }

      }


      async getPaymentStatus (consentId, paymentId, callback) {

        var backendUrl = this.BACKEND_URL + "paymentDetails?consentId=" + consentId + "&paymentId=" + paymentId;

        console.log ("backendUrl : " + backendUrl);

        let accessToken = localStorage.getItem ("access_token");

        let headers = {
          "x-access-token" : accessToken
        };

        this.isLoading = true;

        try {

                let response = await fetch(backendUrl,
                    {
                    method: 'GET',
                    headers: headers,
                    }
                  );

                  console.log ("response : " + JSON.stringify(response));
                  this.isLoading = false;

                        let responseBlob = await response.blob();

                        console.log("responseBlob : " + JSON.stringify(responseBlob));

                        const reader = new FileReader();

                        reader.addEventListener('loadend', (e) => {

                          //var responseJSON = ;
                          this.isLoading = false;

                          try {

                            console.log ("payment detail reader.result : " + reader.result);

                            if (reader.result == "invalid token...") {
                              callback ("invalidToken");
                              return;
                            }

                            let jsonData = JSON.parse (reader.result  as string);

                            console.log ("jsonData : " + jsonData)

                            let errorJson = jsonData ["errorJson"];

                            if (errorJson != undefined) {
                              console.log ("errorJson : " + errorJson)

                              let errorJsonData = JSON.parse (errorJson);
                              console.log ("errorJsonData : " + errorJsonData)

                              var errorMsg = "PaymentDetails failed with : \n\n";

                              errorMsg = errorMsg + errorJsonData ["error"]["code"] + " " + errorJsonData ["error"]["status"] + " " + errorJsonData ["error"]["message"];
                              errorMsg = errorMsg + "\n\n" + errorJsonData ["error"]["tracingId"];

                              alert (errorMsg);

                            } else {


                            let paymentStatus = jsonData.data.payments[0];
                            console.log ("paymentStatus : " + JSON.stringify(paymentStatus));

                            let statusStr = paymentStatus.status;
                            //let statusStr = "PENDING";

                            let refStr = paymentStatus.reference;
                            let payeeName = paymentStatus.payeeDetails.name;
                            let amountPaid = paymentStatus.amount;

                            console.log ("statusStr : " + statusStr);
                            console.log ("payeeName : " + payeeName);
                            console.log ("amountPaid : " + amountPaid);

                            if (statusStr == "COMPLETED") {

                              let paymentRef = this.transferForm.value ["referenceControl"];
                              let paymentId = localStorage.getItem ("paymentId")
                              let uuIdUse = localStorage.getItem ("uuIdUse")

                              console.log ("paymentId : " + paymentId);
                              console.log ("uuIdUse : " + uuIdUse);
                              console.log ("paymentRef : " + paymentRef);

                              this.signInToFirebaseAgain (uuIdUse, paymentId, paymentRef);

                              let emailsToNoti = localStorage.getItem ("emailsToNoti")

                              console.log ("before email : emailsToNotiComp : " + emailsToNoti);
                              // this needs to be enabled

                              let amount = this.transferForm.value ["amountControl"].replace("£", "").replace(",", "")
                              let reference = this.transferForm.value ["referenceControl"]
                              let accountName = this.transferForm.value ["accountNameControl"]

                              console.log ("before email : accountName : " + accountName);
                              console.log ("before email : amount : " + amount);
                              console.log ("before email : reference : " + reference);

                              // this needs to be enabled
                              //this.sendEmailNotification (emailsToNoti, statusStr, accountName, amount, reference)

                              const dialogRef = this.dialog.open(PaymentCompleteDialog, {
                                width: '250px',
                                data: {}
                              });

                              this.resetForm ();

                              dialogRef.afterClosed().subscribe(result => {
                                 console.log ("sending notification");
                                 this.router.navigate(['/thankyou', {
                                 }]);
                                 callback ("ok");
                              });

                            } else {

                              let retryRequestStatuses = ["UNKNOWN", "PENDING"];

                              if (retryRequestStatuses.includes (statusStr)) {

                                console.log ("will try again...");

                                this.isLoading = true;

                                let retryTimer = setTimeout(
                                  () => {
                                  this.getPaymentStatusWithAuthToken (consentId, paymentId, 0)
                                 },
                                  10000
                                )

                                callback ("ok");

                                return;

                              } else {
                                const dialogRef = this.dialog.open(PaymentFailedDialog, {
                                  width: '250px',
                                  data: {}
                                });

                                callback ("ok");

                                return;
                              }



                            }
                          }

                            //responseJSON = JSON.parse (reader.result)
                          } catch (e) {
                            console.log ("jsonParseError : " + e);

                            //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                            callback ("error");

                            //callback(e, []);
                            return;
                          }

                          return null;
                        });

                        reader.readAsText(responseBlob);



            } catch (error) {
                console.log ("getMe error : " + error);
            }


      }


  async getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, currentTry, callback) {

    let accessToken = localStorage.getItem ("access_token")

      console.log ("accessToken : " + accessToken);

      if (accessToken == null) {

        this.getAuthToken ((status, accessToken) => {
          console.log ("status : " + status);
          console.log ("accessToken : " + accessToken);

          if (status == "isOK") {
            localStorage.setItem("access_token", accessToken);

            this.getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, 1, (jsonData, paymentStatus) => {
              callback (jsonData, paymentStatus);
            });
          }
        });
      } else {

        this.getPaymentDetailsFromBackendSnap (paymentUid, userUid, (jsonData, paymentStatus) => {
          callback (jsonData, paymentStatus);

          console.log ("paymentStatus : " + paymentStatus);
          console.log ("jsonData : " + jsonData);

          if ((currentTry == 1) && (jsonData == "Invalid Token")) {
            alert ("Could not get access token after second try...!, Error Code : # 1001");
            callback ({}, "notOk");
            return;
          }

          callback (jsonData, paymentStatus);

        });

      }

  }


  async getPaymentDetailsFromBackendSnap (paymentUid, userUid, callback) {

      let accessToken = localStorage.getItem ("access_token");

      let backendUrl = this.BACKEND_URL + "getuserpayment?paymentUid=" + paymentUid + "&userUid=" + userUid;
      console.log ("backendUrl : " + backendUrl);

      let headers = {
        "x-access-token" : accessToken
      };

      console.log ("accessToken : " + accessToken);

      try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,

            }
          );

        console.log ("response : " + JSON.stringify(response));

        let responseBlob = await response.blob();

        console.log("responseBlob : " + JSON.stringify(responseBlob));

        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {

          //var responseJSON = ;

          try {

            console.log ("reader.result : " + reader.result);

            if (reader.result == "invalid token...") {
              callback ("invalidToken");
              return;
            }


            let jsonData = JSON.parse (reader.result  as string);


            console.log ("jsonData : " + JSON.stringify(jsonData))




            callback (jsonData, "ok");

            //responseJSON = JSON.parse (reader.result)
          } catch (e) {
            console.log ("jsonParseError : " + e);
            callback ({}, "error");

            alert ("The following error occured. Please try again\n\njsonParseError : " + e);
            //callback(e, []);
            return;
          }

          //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

          return null;
        });

        reader.readAsText(responseBlob);

      } catch (error) {

        console.log ("getMe error : " + error);
      }

    }


  async makePaymentEntryBackendSnap (paymentDetails, callback) {

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "makepaymententry";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

    try {

      let response = await fetch(backendUrl,
          {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(paymentDetails)
          }
        );

        console.log ("response : " + JSON.stringify(response));
        this.isLoading = false;

              let responseBlob = await response.blob();

              console.log("responseBlob : " + JSON.stringify(responseBlob));

              const reader = new FileReader();

              reader.addEventListener('loadend', (e) => {

                //var responseJSON = ;


                try {

                  console.log ("payment detail reader.result : " + reader.result);

                  if (reader.result == "invalid token...") {
                    callback ("invalidToken");
                    return;
                  }

                  let jsonData = JSON.parse (reader.result  as string);

                  console.log ("jsonData : " + jsonData)

                  callback (jsonData, "ok");




                  //responseJSON = JSON.parse (reader.result)
                } catch (e) {
                  console.log ("jsonParseError : " + e);

                  //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                  callback ("error");

                  //callback(e, []);
                  return;
                }

                return null;
              });

              reader.readAsText(responseBlob);



  } catch (error) {
      console.log ("getMe error : " + error);
  }

  }

  async testButtonOne () {
    console.log ("testButtonOne");

    let apiManager = new APIManager ();

    let status = "status";
    let merchant = "merchant";
    let amount = "amount";
    let wholesaleAccountNumber = "wholesaleAccountNumber";
    let statusReasonInformation = "statusReasonInformation";
    let bankId = "bankId";

    apiManager.sendDiscordNotification (status, merchant, amount, wholesaleAccountNumber, statusReasonInformation, bankId, (jsonData, redeemTokenStatus) => {
    });


/*
    let errorCode = "SUCCESS"

    localStorage.setItem("error_code", errorCode);

    let apiManager = new APIManager ();

    let tokenId = "tt:34vUTsbkKoPTx76xPwtHrs3dkuz7Y4iPyquunkdS5vkt:HK5cCSU5TAV8nJYQ1oVM";
    let transferId = "t:Bj4HCUYJDvNVHw7PUNSgufygNYtPbCDxV7XYyaHxxs6b:HK5cCSU5TAV8nJYQ1oVM"

    apiManager.getTransferStatus (transferId, (jsonData, redeemTokenStatus) => {

      console.log ("jsonDataz : " + JSON.stringify(jsonData));
      console.log ("redeemTokenStatus : " + redeemTokenStatus);

    });
*/

/*
    apiManager.redeemToken (tokenId, (jsonData, redeemTokenStatus) => {

      console.log ("jsonDataz : " + JSON.stringify(jsonData));
      console.log ("redeemTokenStatus : " + redeemTokenStatus);

    });
  */

    /*
    let emailAddress = "joel@talkingcucumber.com";
    let status = "COMPLETED";
    let recip = "EchoPay";
    let amount = "10.00";
    let refStr= "TEST123";

    apiManager.sendSlackNotificationWithAuth (emailAddress, status, recip, amount, refStr, 0);
    */

    //getTokenRequestResult

    /*
    let requestId = "";

    this.getTokenRequestResultWithAuthToken (requestId, 1, (moreJsonData, morePaymentStatus) => {

      console.log ("moreJsonData : " + moreJsonData);
      console.log ("morePaymentStatus : " + morePaymentStatus);


    });*/

    //this.checkTokenPayment ();

    /*
    let amountStr = "14.00";
    let destSortCode = "123456";
    let destAccountNum = "12345678";
    let destAccountName = "Joel B";
    let paymentDescr = "JoelPayment";

    this.isLoading = true;

    await this.makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 0)
    */
  }

  async getTokenRequestResult (requestId, callback) {

    let accessToken = localStorage.getItem ("access_token");

    console.log ("requestId : " + requestId);

    let backendUrl = this.BACKEND_URL + "getTokenTransferResult?requestId=" + requestId;
    console.log ("backendUrlTr2 : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,
            }
          );

          console.log ("response : " + JSON.stringify(response));
          this.isLoading = false;

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("payment detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }


  }

  async getTokenRequestResultWithAuthToken (requestId, currentTry, callback) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("get token accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("accessToken str");
    }

    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("got accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);


          this.getTokenRequestResultWithAuthToken (requestId, 1, (moreJsonData, morePaymentStatus) => {

            callback (moreJsonData, morePaymentStatus);

          });
        }
      });

    } else {

      await this.getTokenRequestResult (requestId, (moreJsonData, morePaymentStatus) => {

        if (morePaymentStatus == "invalidToken") {

          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1004");

            callback ({}, "invalidToken");
            return;
          }

          this.getTokenRequestResultWithAuthToken (requestId, 1, (evenMoreJsonData, evenMorePaymentStatus) => {

            callback (evenMoreJsonData, evenMorePaymentStatus);
            return;

          });
        }

        callback (moreJsonData, morePaymentStatus);
        return;
      })

    }
  }

  async makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, currentTry) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("token req accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("accessToken str");
    }

    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("got accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);


          this.makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 1);
        }
      });
    } else {

      await this.makeTokenRequest (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, (moreJsonData, morePaymentStatus) => {

        console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
        console.log ("morePaymentStatus : " + morePaymentStatus);

        if (morePaymentStatus == "invalidToken") {

          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1002");
            return;
          }

          this.makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 1);
          return;

        }

        let statusCode = moreJsonData.statusCode

        console.log ("statusCode : " + statusCode);

        if (statusCode == "200") {
          let requestId = moreJsonData.requestId
          console.log ("requestId : " + requestId);

          //https://web-app.sandbox.token.io/app/request-token/rq:41rDvoe7GswaieMg215cLq6SJDPB:5zKtXEAq

          let tokenUrl = Constants.TOKEN_REQUEST_URL + requestId;

          console.log ("tokenUrl : " + tokenUrl);

          window.location.href = tokenUrl;
        }

      });

    }

  }

  async makeTokenRequest (amountStr, destSortCode, destAccountNum, destAccountName, paymentDesr, callback) {

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "makeTokenTransfer";
    console.log ("backendUrl4 : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    let paymentDetails = {
        "amountStr" : amountStr,
        "destSortCode" : destSortCode,
        "destAccountNum" : destAccountNum,
        "destAccountName" : destAccountName,
        "paymentDescr" : paymentDesr
    }

    console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

    try {

        let response = await fetch(backendUrl,
            {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(paymentDetails)
            }
          );

          console.log ("response : " + JSON.stringify(response));
          this.isLoading = false;

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("payment detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }

  }

}



export interface DialogData {
  qrCode: string;
}

@Component({
  selector: 'qrcode-dialog',
  templateUrl: 'PaymentCompleteDialog.html',
})
export class PaymentCompleteDialog {


  value = 'https://www.techiediaries.com/';

  constructor(
    public dialogRef: MatDialogRef<PaymentCompleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.value = data.qrCode;


    }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'failed-dialog',
  templateUrl: 'PaymentFailedDialog.html',
})
export class PaymentFailedDialog {


  value = 'https://www.techiediaries.com/';

  constructor(
    public dialogRef: MatDialogRef<PaymentCompleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      //this.value = data.qrCode;


    }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'fcawarning-dialog',
  templateUrl: 'FCAWarningDialog.html',
})
export class FCAWarningDialog {


  value = 'https://www.techiediaries.com/';

  constructor(
    public dialogRef: MatDialogRef<PaymentCompleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      var targetDate = new Date();
      targetDate.setDate(targetDate.getDate() + 90);

      var dd = targetDate.getDate();
      var mm = targetDate.getMonth() + 1; // 0 is January, so we must add 1
      var yyyy = targetDate.getFullYear();

      var dateString = dd + "/" + mm + "/" + yyyy;

      this.value = dateString;


    }


  onNoClick(): void {
    this.dialogRef.close();
  }



}





