import { Component, OnInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-qrcodescenner',
  templateUrl: './qrcodescenner.component.html',
  styleUrls: ['./qrcodescenner.component.css']
})


export class QrcodescennerComponent implements OnInit {


availableDevices: MediaDeviceInfo[];
deviceCurrent: MediaDeviceInfo;
deviceSelected: string;

async ngOnInit() {
}

formatsEnabled: BarcodeFormat[] = [
  BarcodeFormat.CODE_128,
  BarcodeFormat.DATA_MATRIX,
  BarcodeFormat.EAN_13,
  BarcodeFormat.QR_CODE,
];

hasDevices: boolean;
hasPermission: boolean;

qrResultString: string;

torchEnabled = false;
//torchAvailable$ = new BehaviorSubject<boolean>(false);
tryHarder = false;

constructor(
  private route: ActivatedRoute,
  private router: Router,
) {

}

clearResult(): void {
  console.log ("");
  this.qrResultString = null;
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
  console.log ("onCamerasFound");
  this.availableDevices = devices;
  this.hasDevices = Boolean(devices && devices.length);
}

onCodeResult(resultString: string) {
  console.log ("onCodeResult : " + resultString);
  this.qrResultString = resultString;

  //alert ("Found code : " + this.qrResultString)

//  let urlFind = "https://web.ep7.co.uk/";
//  let urlFind = "https://192.168.0.150/";
  let urlFind = "https://95.179.225.6/";

  if (this.qrResultString.includes (urlFind)) {
    console.log ("isEchoPos");

    let eDataBits = this.qrResultString.split ("?");

    let urlParams = eDataBits [1];

    console.log ("urlParams : " + urlParams);

    let urlParamsBits = urlParams.split ("&");

    console.log ("urlParamsBits : " + urlParamsBits);

    let paramsUse = {};

    paramsUse["invoiceNumber"] = "";

    for (const urlParamBit of urlParamsBits) {

      let urlParamsBittis = urlParamBit.split ("=");

      console.log ("key : " + urlParamsBittis[0]);
      console.log ("value : " + urlParamsBittis[1]);

      if (urlParamsBittis[0] == "insr") {
        //paramsUse["customerId"] = urlParamsBittis[1];
        //paramsUse["siteRef"] = urlParamsBittis[1];
      }

      if (urlParamsBittis[0] == "inam") {
        paramsUse["amount"] = urlParamsBittis[1];
      }

      if (urlParamsBittis[0] == "inuu") {
        paramsUse["userUid"] = urlParamsBittis[1];
      }

      if (urlParamsBittis[0] == "inpi") {
        paramsUse["paymentUid"] = urlParamsBittis[1];
      }

      if (urlParamsBittis[0] == "inan") {
        paramsUse["accountNum"] = urlParamsBittis[1];
      }
    }

    console.log ("paramsUse : " + JSON.stringify(paramsUse));

    this.router.navigate(['/confirmbranch', paramsUse]);

    return;
  }

  let eDataBits = this.qrResultString.split (",");
  console.log ("eDataBits : " + eDataBits.length);

  if ((eDataBits.length != 4) && (eDataBits.length != 5)) {
    return;
  }

  let siteRef = eDataBits [0];
  let custAccountNumber = eDataBits [1];
  let invoiceNumber = eDataBits [2];
  let invoiceValue = eDataBits [3];

  let echoPosPaymentId = "";

  console.log ("siteRef : " + siteRef);
  console.log ("custAccountNumber : " + custAccountNumber);
  console.log ("invoiceNumber : " + invoiceNumber);
  console.log ("invoiceValue : " + invoiceValue);

  // echoPos payments
  if (eDataBits.length == 5) {

    let paymentUid = eDataBits [4];
    let userUid = eDataBits [2];

    console.log ("paymentUid : " + paymentUid + ", userUid : " + userUid);

    this.router.navigate(['/confirmbranch', {
    "paymentUid": paymentUid,
    "accountNum": custAccountNumber,
    "userUid": userUid,
    "amount":invoiceValue,
    //"echoPosPaymentId":echoPosPaymentId
    }]);

    return;
  }

  //let echoPosPaymentId = eDataBits [4];


  //console.log ("echoPosPaymentId : " + echoPosPaymentId);

  this.router.navigate(['/confirmbranch', {
  "siteRef": siteRef,
  "accountNum": custAccountNumber,
  "invoiceNumber": invoiceNumber,
  "amount":invoiceValue,
  //"echoPosPaymentId":echoPosPaymentId
  }]);

}

onDeviceSelectChange(selected: string) {
  console.log ("onDeviceSelectChange");
  const selectedStr = selected || '';
  if (this.deviceSelected === selectedStr) { return; }
  this.deviceSelected = selectedStr;
  const device = this.availableDevices.find(x => x.deviceId === selected);
  this.deviceCurrent = device || undefined;
}

onDeviceChange(device: MediaDeviceInfo) {
  console.log ("onDeviceChange");

  //const selectedStr = device?.deviceId || '';
  //if (this.deviceSelected === selectedStr) { return; }
  //this.deviceSelected = selectedStr;
  //this.deviceCurrent = device || undefined;
}

openFormatsDialog() {
  const data = {
    formatsEnabled: this.formatsEnabled,
  };


}

onHasPermission(has: boolean) {
  console.log ("onHasPermission");
  this.hasPermission = has;
}

openInfoDialog() {
  const data = {
    hasDevices: this.hasDevices,
    hasPermission: this.hasPermission,
  };

  //this._dialog.open(AppInfoDialogComponent, { data });
}

onTorchCompatible(isCompatible: boolean): void {
  //this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
  this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
  this.tryHarder = !this.tryHarder;
}

}
