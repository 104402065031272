import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';

import {FormControl, FormGroup, FormBuilder, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'bankchooser',
  templateUrl: 'BankChooserDialog.html',
  styleUrls: ['BankChooserDialog.css'],
})
export class BankChooserDialog  implements OnInit {

  bankChooserForm: FormGroup;

  panelOpenState = false;
  accordionList:any;

  allBanks = [];

//  BACKEND_URL = "https://talkingcucumber.info/";
  BACKEND_URL = "https://ep7b.com/";

ngOnInit() {

  this.getBanksWithAuthToken (0);
}

  constructor(
    private formBuilder: FormBuilder,
  ) {
      this.bankChooserForm = this.formBuilder.group({
        cashControl: [null, []],
      });



    }



    changeBankCheckBox(theBankName){



      //console.log ("changeBankCheckBox : " + theBankName);
      localStorage.setItem("bankSelected", theBankName + "");

      var bankTmpArray = [];

      for (var bankie of this.allBanks) {

        //console.log ("bankie : " + bankie.id);

        if (bankie.id == theBankName) {
          //console.log ("matched : " + theBankName);
        } else {
          bankie.checked = "";
        }

        if (bankie.type == "group") {

          var groupTmp = [];

          //console.log ("bankie.banks : " + bankie.banks);

          for (var subBankie of bankie.banks) {

            //console.log ("subBankie : " + subBankie.fullName + ", id : " + subBankie.id);

            if (subBankie.id != theBankName) {
              subBankie.checked = "";
            } else {
              subBankie.checked = true;
            }

            groupTmp.push (subBankie)
          }

          bankie.banks = groupTmp;


/*
          for (var subBankie of bankie.banks) {
            if (subBankie.id != theBankName) {
              subBankie.checked = "";
            }

            //groupTmp.push (subBankie);
          }


          bankie.banks = subBankie;
*/
        }

        bankTmpArray.push (bankie);

      }

      //console.log ("pushing change");
      this.allBanks = bankTmpArray;

    }





  onNoClick(): void {
    //this.dialogRef.close();



  }

  async getBanksWithAuthToken (currentTry) {

      let accessToken = localStorage.getItem ("access_token")

      console.log ("gbwat : accessToken : " + accessToken);

      if (accessToken == "null") {
        console.log ("gbwat : accessToken is null");
      } else {
        console.log ("gbwat : accessToken is not null");
      }




      if ((accessToken == null) || (accessToken == "null")) {

        this.getAuthToken ((status, accessToken) => {
          console.log ("gbwat : status : " + status);
          console.log ("gbwat : accessToken : " + accessToken);

          if (status == "isOK") {
            localStorage.setItem("access_token", accessToken);

            this.getBanksWithAuthToken (1);
          }
        });
      } else {
        this.getBanks ((bankGetStatus) => {
          console.log ("gbwatB : bankGetStatus : " + bankGetStatus);

          if (bankGetStatus.includes ("Error : ")) {
            alert (bankGetStatus);
            return;
          }

          if (bankGetStatus != "ok") {
            if (currentTry == 1) {
              alert ("Could not get access token after second try...!, Error Code : # 1004");
              return;
            }

            if (bankGetStatus == "invalidToken") {
              this.getBanksWithAuthToken (1);
            }
          }

          return;




        })
      }

    }

  async getAuthToken (callback) {

      console.log ("getAuthToken");

      let backendUrl = this.BACKEND_URL + "getAuthToken?clientSecret=IZZYSEKKER2022";
      console.log ("backendUrl : " + backendUrl);

      let headers = {
        "Content-Type" : "application/json"
      };

          try {

                  let response = await fetch(backendUrl,
                      {
                      method: 'GET',
                      headers: headers,
                      }
                    );

                  console.log ("response : " + JSON.stringify(response));

                  let responseBlob = await response.blob();

                  console.log("responseBlob : " + JSON.stringify(responseBlob));

                  const reader = new FileReader();

                  reader.addEventListener('loadend', (e) => {

                    //var responseJSON = ;

                    try {

                      console.log ("reader.result : " + reader.result);

                      let jsonData = JSON.parse (reader.result  as string);
                      console.log ("jsonData : " + jsonData)

                      if (jsonData.status == "isOK") {

                        let accessToken = jsonData.access_token;

                        console.log ("accessToken : " + accessToken);

                        callback (jsonData.status, accessToken);

                      } else {
                        callback (jsonData.status, "");
                      }


                      //responseJSON = JSON.parse (reader.result)
                    } catch (e) {
                      console.log ("jsonParseError : " + e);
                      alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                      //callback(e, []);
                      return;
                    }

                    //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                    return null;
                  });

                  reader.readAsText(responseBlob);

                } catch (error) {

                  callback ("failed", "");

                  console.log ("getMe error : " + error);

                  //return error

                }

    }


  async getBanks (callback) {

      let accessToken = localStorage.getItem ("access_token");

      let backendUrl = this.BACKEND_URL + "banks";
      console.log ("gb : backendUrl : " + backendUrl);
      console.log ("gb : accessToken : " + accessToken);

      let headers = {
        "x-access-token" : accessToken
      };

      try {



              let response = await fetch(backendUrl,
                  {
                  method: 'GET',
                  headers: headers,

                  }
                );

              console.log ("response : " + JSON.stringify(response));

              let responseBlob = await response.blob();

              console.log("responseBlob : " + JSON.stringify(responseBlob));

              const reader = new FileReader();

              reader.addEventListener('loadend', (e) => {

                //var responseJSON = ;

                try {

                  console.log ("reader.result : " + reader.result);



                  if (reader.result == "invalid token...") {
                    callback ("invalidToken");
                    return;
                  }


                  let jsonData = JSON.parse (reader.result  as string);

                  if (jsonData ["message"] != undefined) {
                    console.log ("got error : " + jsonData ["message"]);
                    callback ("Error : " + jsonData ["message"]);
                    return;
                  }

                  var banksFound = [];

                  for (var bankie of jsonData) {
                    console.log ("bank.fullName : " + bankie.fullName)

                    var bank = bankie;

                    bank.checked = false;

                    let imgs = bankie.media;

                    for (const img of imgs) {
                      if (img.type == "icon") {
                        bank.logoImg = img;
                      }
                    }

                    banksFound.push (bank);

                  }

                  let bankGroups = ["Allied Irish", "Bank of Ireland", "Bank of Scotland", "Barclays", "HSBC", "Handelsbanken", "Lloyds", "Royal Bank of Scotland", "Virgin"];

                  var bankGroupBanks = {};
                  var bankItems = [];

                  for  (var i = 0; i < banksFound.length; i++) {
                    console.log ("bankzs : " + banksFound[i].name);
                    //console.log ("banks : " + JSON.stringify(banks[i]));

                    var isPartOfGroup = false;

                    for (const bankGroup of bankGroups) {

                      var isInGroup = banksFound[i].fullName.includes (bankGroup);

                      if (bankGroup == "Bank of Scotland") {
                        if (banksFound[i].fullName.includes ("Royal")) {
                          isInGroup = false
                        }
                      }

                      if (isInGroup) {

                        isPartOfGroup = true;

                        console.log ("is in group : " + bankGroup);

                        if (bankGroupBanks [bankGroup] == undefined) {
                          bankGroupBanks [bankGroup] = [banksFound[i]];
                        } else {
                          let banksGotAll = bankGroupBanks [bankGroup];
                          banksGotAll.push (banksFound[i]);
                          bankGroupBanks [bankGroup] = banksGotAll;
                        }
                      }
                    }

                    if (!isPartOfGroup) {
                      bankItems.push (banksFound[i]);
                    }
                  }

                  for (const [bankGroupName, bankGroupBankies] of Object.entries(bankGroupBanks)) {
                    console.log ("bankGroupName : " + bankGroupName);

                    let firstBank = bankGroupBankies [0];

                    //console.log ("firstBank : " + JSON.stringify (firstBank));

                    let iconUrl = firstBank.logoImg.source;

                    console.log ("iconUrl : " + iconUrl);

                    let imgUrl = {
                      "source" : iconUrl
                    }

                    let bankieGroup = {
                      "id" : bankGroupName + " Group",
                      "type" : "group",
                      "banks" : bankGroupBankies,
                      "fullName" : bankGroupName,
                      "logoImg" : imgUrl,
                    }

                    bankItems.push (bankieGroup);
                  }


                  let banksSorted = bankItems.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)

                  console.log ("banksSorted.length : " + banksSorted.length);



                  /*
                  let tempArr:any[] = Object.values(banksSorted) as any[];

                  this.allBanks = of (tempArr);

                  */

                  for (const bankMade in banksSorted) {

                    let theBank = banksSorted [bankMade];

                    console.log ("bankMade : " + JSON.stringify(theBank));
                    //this.allBanks.
                  }


                  this.allBanks = banksSorted;


                  //this.allBanks = banksSorted;

                  console.log ("jsonData : " + jsonData)

                  callback ("ok");

                  //responseJSON = JSON.parse (reader.result)
                } catch (e) {
                  console.log ("jsonParseError : " + e);
                  callback ("error");

                  alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                  //callback(e, []);
                  return;
                }

                //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                return null;
              });

              reader.readAsText(responseBlob);

            } catch (error) {


              console.log ("getMe error : " + error);

              //return error

            }

    }



}
