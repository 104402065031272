import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
//import { AngularFireDatabase } from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import { CommonModule, CurrencyPipe} from '@angular/common';

//import 'rxjs/add/operator/take'
import { take } from 'rxjs/operators';

import Constants from "../../shared/Constants";

import {FormControl, FormGroup, FormBuilder, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-confirmbranch',
  templateUrl: './confirmbranch.component.html',
  styleUrls: ['./confirmbranch.component.css']
})
export class ConfirmbranchComponent implements OnInit {

  branchForm: FormGroup;

  branchIdUse: string;

  accountNumRegex = /^\d\d\d\d\d\d\d\d$/;

  USE_TOKEN = true;

  isLoading = false;

  BACKEND_URL = "https://talkingcucumber.org/";
  //BACKEND_URL = "https://talkingcucumber.info/";
  //BACKEND_URL = "https://ep7b.com/";

  amountToPay = 0.00;

  paymentIdUse: string;
  uuIdUse: string;

  authButtonDisabled = "false"

  // need to get this from api...!
  custNames = [
    {name: "Head Office Branch", id: "HE"},
    {name: "Maxwell Branch", id: "MA"},
    {name: "Queenslie", id: "UQ"},
    {name: "M9 Branch", id: "M9"},
  ];

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    //public afDb: AngularFireDatabase,
    private route:ActivatedRoute,
    private currencyPipe : CurrencyPipe,
    private router: Router,
  ) { }

  ngOnInit(): void {

    localStorage.setItem("access_token", null);

    this.branchForm = this.formBuilder.group({
      branchSelectControl: [{disabled: true}, [Validators.required]],
      accountNumControl: [null, [Validators.required]],
      amountControl: [null, [Validators.required]],
      cashControl: [null, []],
      offAccountControl: [null, []]
    });

    let queryString = window.location.search;
    console.log ("queryString : " + queryString);

    let urlParams = new URLSearchParams(queryString);

    let inSr = this.route.snapshot.paramMap.get('insr')
    let inCn = this.route.snapshot.paramMap.get('incn')
    let inIv = this.route.snapshot.paramMap.get('iniv')
    let inIa = this.route.snapshot.paramMap.get('inia')

    console.log ("inSr : " + inSr + ", inCn : " + inCn + ", inIv : " + inIv + ", inIa : " + inIa);

    var branchNameTmp = this.route.snapshot.paramMap.get('siteRef');
    console.log ("branchNameTmp : " + branchNameTmp);

    if (inSr != null) {
      branchNameTmp = inSr;
    }


    let branchName = branchNameTmp;

    var amount = this.route.snapshot.paramMap.get('amount');

    if (inIa != null) {
      amount = inIa;
    }

    var accountNum = this.route.snapshot.paramMap.get('accountNum');

    if (inCn != null) {
      accountNum = inCn;
    }

    var invoiceNumber = this.route.snapshot.paramMap.get('invoiceNumber');

    var branchId = "";

    if (inIv != null) {
      branchId = inIv;
      this.branchIdUse = branchId;
    }

    let echoPosPaymentId = this.route.snapshot.paramMap.get('echoPosPaymentId');

    let paymentUid = this.route.snapshot.paramMap.get('paymentUid');
    let userUid = this.route.snapshot.paramMap.get('userUid');

    this.paymentIdUse = paymentUid;
    this.uuIdUse = userUid;

    if (paymentUid != null) {

      if (!this.USE_TOKEN) {
        console.log ("offToFirebase...");
        this.signInToFirebase (paymentUid, userUid, "");
      }


    }

    console.log ("branchName : " + branchName);
    console.log ("amount : " + amount);
    console.log ("invoiceNumber : " + invoiceNumber);
    console.log ("echoPosPaymentId : " + echoPosPaymentId);
    console.log ("paymentUid : " + paymentUid);
    console.log ("userUid : " + userUid);

    if (this.USE_TOKEN) {
      console.log ("usingToken");

      localStorage.setItem("paymentIdz", paymentUid);
      localStorage.setItem("uuIdUse", userUid);

      console.log ("stored early!");

      this.getDetailsFromBackend (userUid, "EchoWebQR", paymentUid, "0.00");
      return;
    }


    if (accountNum != undefined) {
      this.branchForm.controls['accountNumControl'].setValue(accountNum);
    }

    if (amount != undefined) {

          let amountNice = this.currencyPipe.transform(amount, '£');
          this.branchForm.controls['amountControl'].setValue(amountNice);
        }

    if (branchName != undefined) {
      let branchNameIdx = this.getBranchNameId (branchName)

      console.log ("branchNameIdx : " + branchNameIdx);

      this.branchForm.controls['branchSelectControl'].setValue(branchName)
    } else {
      this.branchForm.controls['branchSelectControl'].setValue("")
    }






  }

  changeCashCheckBox(event: any){

    let cashBoxValue = this.branchForm.value ["cashControl"]

    if (cashBoxValue) {
      this.branchForm.controls['offAccountControl'].setValue(false);
    }
  }

  changeChequeCheckBox(event: any){

      let cashBoxValue = this.branchForm.value ["offAccountControl"]

      if (cashBoxValue) {
        this.branchForm.controls['cashControl'].setValue(false);
      }
    }

  authorisePayment() {

    console.log ("authorisePayment : " + this.branchForm.valid);

    if (!this.branchForm.valid) {
      return;
    }

    if (this.authButtonDisabled == "true") {
      return;
    }

    this.authButtonDisabled = "true"

    let branchId = this.branchIdUse;

    // re-enable so we can read the value
    this.branchForm.controls['amountControl'].enable();

    let accountNum = this.branchForm.value ["accountNumControl"]
    let amount = this.branchForm.value ["amountControl"].replace("£", "").replace(",", "")
    let cashBoxValue = this.branchForm.value ["cashControl"]
    let chequeBoxValue = this.branchForm.value ["offAccountControl"]

    this.amountToPay = amount;

    console.log ("branchId : " + branchId);
    console.log ("accountNum : " + accountNum);
    console.log ("amount : " + amount);
    console.log ("cashBoxValue : " + cashBoxValue);
    console.log ("chequeBoxValue : " + chequeBoxValue);

    //this.getDetailsFromFirebase ();
    console.log ("get branchid : " + branchId);
    this.signInToFirebase ("", "", branchId);
  }


  async signInToFirebase (paymentUid, userUid, customerId) {

    if (paymentUid != "") {

      console.log ("getting payment details");

      //this.getPaymentDetailsFromFirebase (paymentUid, userUid);
      //this.getPaymentDetailsFromFirebaseSnap (paymentUid, userUid);
      await this.getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, 0, (jsonData, paymentStatus) => {

        console.log ("jsonData : " + JSON.stringify(jsonData));
        console.log ("paymentStatus : " + paymentStatus);

        if (paymentStatus != "ok") {
          alert ("Could not find payment " + paymentUid + " for user " + userUid);
          return;
        }

        let custAccountNumber = jsonData ["accountNumber"];
        let amount = jsonData ["amountPay"];
        let branchId = jsonData ["customerId"];
        let branchName = jsonData ["customerName"];
        let accountName = jsonData ["destAccountName"];
        let accountNum = jsonData ["destAccountNum"];
        let emailsToNoti = jsonData ["destEmail"];
        let sortCode = jsonData ["destSortCode"];

        if (branchName != null) {
          console.log ("setBranch");
          this.branchForm.controls['branchSelectControl'].setValue(branchName);

        }

        this.branchForm.controls['branchSelectControl'].disable();

        if (amount != undefined) {
          let amountNice = this.currencyPipe.transform(amount, '£');
          this.branchForm.controls['amountControl'].setValue(amountNice);
          this.branchForm.controls['amountControl'].disable();
        }

        this.branchIdUse = branchId;
        console.log ("this.branchIdUse : " + this.branchIdUse);

        this.branchForm.controls['accountNumControl'].setValue(custAccountNumber);

        console.log ("amount : " + amount);
        console.log ("custAccountNumber : " + custAccountNumber);
      });

      return;
    }

    console.log ("customerId : " + customerId);

    await this.getBranchByIdAuth (customerId, 0, (jsonData, paymentStatus) => {

      console.log ("jsonData : " + JSON.stringify(jsonData));
      console.log ("paymentStatus : " + paymentStatus);

      if (paymentStatus != "ok") {
        alert ("Could not find branch for id : " + customerId)
        return;
      }

      let fullName = jsonData ["custName"];
      let prefixCode = jsonData ["custInvoiceRef"];
      let shortName = jsonData ["custInvoiceRef"];
      let branchId = jsonData ["customerId"];
      let accountName = jsonData ["destAccountName"];
      let accountNum = jsonData ["destAccountNum"];
      let sortCode = jsonData ["destSortCode"];
      let emailsToNoti = jsonData ["email"];

      console.log ("fullName : " + fullName);
      console.log ("prefixCode : " + prefixCode);
      console.log ("shortName : " + fullName);
      console.log ("branchId : " + branchId);
      console.log ("accountName : " + accountName);
      console.log ("accountNum : " + accountNum);
      console.log ("sortCode : " + sortCode);
      console.log ("emailsToNoti : " + emailsToNoti);

      let cashBoxValue = this.branchForm.value ["cashControl"]
      let chequeBoxValue = this.branchForm.value ["offAccountControl"]

      var cashCheStr = "";

      if (cashBoxValue) {
        cashCheStr = "CA";
      }

      if (chequeBoxValue) {
        cashCheStr = "CR";
      }

      let customerAccountNum = this.branchForm.value ["accountNumControl"];

      console.log ("cashBoxValue : " + cashBoxValue + ", chequeBoxValue : " + chequeBoxValue + ", cashCheStr : " + cashCheStr + ", customerAccountNum : " + customerAccountNum);

      console.log ("paymentIdUse : " + this.paymentIdUse + ", uuIdUse : " + this.uuIdUse);

      this.router.navigate(['/pay', {
      "fullName": fullName,
      "prefixCode": prefixCode,
      "shortName": shortName,
      "branchId":branchId,
      "accountName":accountName,
      "customerAccountNum":customerAccountNum,
      "accountNum":accountNum,
      "sortCode":sortCode,
      "cashCheStr":cashCheStr,
      "emailsToNoti":emailsToNoti,
      "amountToPay":this.amountToPay,

      "paymentId":this.paymentIdUse,
      "uuIdUse":this.uuIdUse,
      }]);

    });

    /*
    this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
        console.log ("login success...! cb");

        if (customerId != "") {

          console.log ("getDetailsFromFirebaseSnap : " + customerId);

          this.getDetailsFromFirebaseSnap (customerId);
          return;
        }




    }).catch((error) => {
      console.log ("error : " + error.message);
      window.alert(error.message)
    })*/


  }

  async getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, currentTry, callback) {

      let accessToken = localStorage.getItem ("access_token")

        console.log ("snapAuth : accessToken : " + accessToken);

        if (accessToken == "null") {
          console.log ("gpd : accessToken null");
        }

        /*
        if (accessToken == undefined) {
          console.log ("accessToken undefined");
        }

        if (accessToken == "") {
          console.log ("accessToken empty");
        }*/

        if (accessToken == "null") {

          this.getAuthToken ((status, accessToken) => {
            console.log ("status : " + status);
            console.log ("accessToken : " + accessToken);

            if (status == "isOK") {
              localStorage.setItem("access_token", accessToken);

              this.getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, 1, (jsonData, paymentStatus) => {
                callback (jsonData, paymentStatus);
              });
            }
          });
        } else {

          this.getPaymentDetailsFromBackendSnap (paymentUid, userUid, (jsonData, paymentStatus) => {
            callback (jsonData, paymentStatus);

            console.log ("paymentStatus : " + paymentStatus);
            console.log ("jsonData : " + jsonData);

            if ((currentTry == 1) && (paymentStatus == "invalidToken")) {
              alert ("Could not get access token after second try...!, Error Code : # 1001");
              callback ({}, "notOk");
              return;
            }

            callback (jsonData, paymentStatus);

          });

        }

    }


  async getPaymentDetailsFromBackendSnap (paymentUid, userUid, callback) {

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "getuserpayment?paymentUid=" + paymentUid + "&userUid=" + userUid;
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken
    };

    console.log ("accessToken : " + accessToken);

    try {

      let response = await fetch(backendUrl,
          {
          method: 'GET',
          headers: headers,

          }
        );

      console.log ("response : " + JSON.stringify(response));

      let responseBlob = await response.blob();

      console.log("responseBlob : " + JSON.stringify(responseBlob));

      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {

        //var responseJSON = ;

        try {

          console.log ("getpayment : reader.result : " + reader.result);

          if (reader.result == "invalid token...") {
            callback ({}, "invalidToken");
            return;
          }


          let jsonData = JSON.parse (reader.result  as string);


          console.log ("jsonData : " + JSON.stringify(jsonData))




          callback (jsonData, "ok");

          //responseJSON = JSON.parse (reader.result)
        } catch (e) {
          console.log ("jsonParseError : " + e);
          callback ({}, "error");

          alert ("The following error occured. Please try again\n\njsonParseError : " + e);
          //callback(e, []);
          return;
        }

        //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

        return null;
      });

      reader.readAsText(responseBlob);

    } catch (error) {

      console.log ("getMe error : " + error);
    }

  }

/*
  getPaymentDetailsFromFirebaseSnap (paymentUid, userUid) {

    let paymentPath = '/users/' + userUid + "/payments/" + paymentUid;
    console.log ("paymentPath : " + paymentPath);

    let itemRef = this.afDb.object(paymentPath);



    itemRef.snapshotChanges().pipe(take(1)).subscribe(action => {

      let payload = action.payload.val();

      console.log(JSON.stringify(payload))

      let custAccountNumber = payload ["accountNumber"];
      let amount = payload ["amountPay"];
      let branchId = payload ["customerId"];
      let branchName = payload ["customerName"];
      let accountName = payload ["destAccountName"];
      let accountNum = payload ["destAccountNum"];
      let emailsToNoti = payload ["destEmail"];
      let sortCode = payload ["destSortCode"];

      console.log ("accountName : " + accountName);
      console.log ("branchName : " + branchName);
      console.log ("accountNum : " + accountNum);
      console.log ("sortCode : " + sortCode);
      console.log ("emailsToNoti : " + emailsToNoti);



      if (branchName != null) {
        console.log ("setBranch");
        this.branchForm.controls['branchSelectControl'].setValue(branchName);

      }

      this.branchForm.controls['branchSelectControl'].disable();

      if (amount != undefined) {
        let amountNice = this.currencyPipe.transform(amount, '£');
        this.branchForm.controls['amountControl'].setValue(amountNice);
      }



      this.branchIdUse = branchId;
      console.log ("this.branchIdUse : " + this.branchIdUse);

      this.branchForm.controls['accountNumControl'].setValue(custAccountNumber);

      console.log ("amount : " + amount);
      console.log ("custAccountNumber : " + custAccountNumber);

    });

  }
  */

  transformAmount(element){

        console.log ("element : " + element.target.value);

        let tmpAmount = this.currencyPipe.transform(element.target.value.replace("£", "").replace(",", ""), '£');
        console.log ("tmpAmount : " + tmpAmount);

        element.target.value = tmpAmount;
    }

/*
  getPaymentDetailsFromFirebase (paymentUid, userUid) {

    let paymentPath = '/users/' + userUid + "/payments/" + paymentUid;

    console.log ("paymentPath : " + paymentPath);

    this.afDb.list(paymentPath)
      .valueChanges()
      .subscribe((datas) => {
        console.log("datas" + JSON.stringify (datas))
        console.log("datas" + datas)

        //datas["Head Office Branch","EP","Younas, Liz","HE","United Wholesale Scotland Limited","10127829","090222","mohammad.younas@uniteduk.com,liz.convery@uniteduk.com,echopay@uniteduk.com"]

        let custAccountNumber = datas [0];
        let amount = datas [1] + "";
        let branchId = datas [2] + "";
        let branchName = datas [3];
        let accountName = datas [4];
        let accountNum = datas [5];
        let emailsToNoti = datas [6];
        let sortCode = datas [7];

        console.log ("accountName : " + accountName);
        console.log ("branchName : " + branchName);
        console.log ("accountNum : " + accountNum);
        console.log ("sortCode : " + sortCode);
        console.log ("emailsToNoti : " + emailsToNoti);

        if (branchName != null) {
          console.log ("setBranch");
          this.branchForm.controls['branchSelectControl'].setValue(branchName);

        }

        this.branchForm.controls['branchSelectControl'].disable();

        let amountNice = this.currencyPipe.transform(amount, '£');
        this.branchForm.controls['amountControl'].setValue(amountNice);

        this.branchIdUse = branchId;

        this.branchForm.controls['accountNumControl'].setValue(custAccountNumber);

        console.log ("amount : " + amount);
        console.log ("custAccountNumber : " + custAccountNumber);


      },  (err)=> {
        console.log("probleme : ", err)
        alert ("That payment wasn't found...!")
      }
    );


  }

  getDetailsFromFirebase (customerId) {

    this.afDb.list("customers/" + customerId)
      .valueChanges()
      .subscribe((datas) => {
        console.log("datas" + JSON.stringify (datas))
        console.log("datas" + datas)

        //datas["Head Office Branch","EP","Younas, Liz","HE","United Wholesale Scotland Limited","10127829","090222","mohammad.younas@uniteduk.com,liz.convery@uniteduk.com,echopay@uniteduk.com"]

        let fullName = datas [0];
        let prefixCode = datas [1];
        let shortName = datas [2];
        let branchId = datas [3];
        let accountName = datas [4];
        let accountNum = datas [5];
        let sortCode = datas [6];
        let emailsToNoti = datas [7];

        console.log ("fullName : " + fullName);
        console.log ("prefixCode : " + prefixCode);
        console.log ("shortName : " + fullName);
        console.log ("branchId : " + branchId);
        console.log ("accountName : " + accountName);
        console.log ("accountNum : " + accountNum);
        console.log ("sortCode : " + sortCode);
        console.log ("emailsToNoti : " + emailsToNoti);

        let cashBoxValue = this.branchForm.value ["cashControl"]
        let chequeBoxValue = this.branchForm.value ["offAccountControl"]

        var cashCheStr = "";

        if (cashBoxValue) {
          cashCheStr = "CA";
        }

        if (chequeBoxValue) {
          cashCheStr = "CR";
        }

        let customerAccountNum = this.branchForm.value ["accountNumControl"];

        console.log ("cashBoxValue : " + cashBoxValue + ", chequeBoxValue : " + chequeBoxValue + ", cashCheStr : " + cashCheStr + ", customerAccountNum : " + customerAccountNum);

        console.log ("paymentIdUse : " + this.paymentIdUse + ", uuIdUse : " + this.uuIdUse);

        this.router.navigate(['/pay', {
        "fullName": fullName,
        "prefixCode": prefixCode,
        "shortName": shortName,
        "branchId":branchId,
        "accountName":accountName,
        "customerAccountNum":customerAccountNum,
        "accountNum":accountNum,
        "sortCode":sortCode,
        "cashCheStr":cashCheStr,
        "emailsToNoti":emailsToNoti,
        "amountToPay":this.amountToPay,

        "paymentId":this.paymentIdUse,
        "uuIdUse":this.uuIdUse,

        }]);

        //return allItems;

      },  (err)=> {
        console.log("probleme : ", err)
      }
    );

  }
*/

  //payload{"amountPay":"","custAccount":"","custBranch":"BBW Unitas","custInvoiceRef":"EP","custName":"Unitas","customerId":"UN","destAccountName":"Unitas Wholesale Limited","destAccountNum":"43940667","destSortCode":"204867","email":"ben@olthem.com","status":"notactive"}
/*
  getDetailsFromFirebaseSnap (customerId) {

      let customerPath = "customers/" + customerId;

      console.log ("getDetailsFromFirebaseSnap");

      console.log ("customerPath : " + customerPath);

      let itemRef = this.afDb.object(customerPath);

      itemRef.snapshotChanges().pipe(take(1)).subscribe(action => {

          let payload = action.payload.val();

          console.log("payload" + JSON.stringify (payload))
          //console.log("datas" + datas)
          //datas["Head Office Branch","EP","Younas, Liz","HE","United Wholesale Scotland Limited","10127829","090222","mohammad.younas@uniteduk.com,liz.convery@uniteduk.com,echopay@uniteduk.com"]

          let fullName = payload ["custName"];
          let prefixCode = payload ["custInvoiceRef"];
          let shortName = payload ["custInvoiceRef"];
          let branchId = payload ["customerId"];
          let accountName = payload ["destAccountName"];
          let accountNum = payload ["destAccountNum"];
          let sortCode = payload ["destSortCode"];
          let emailsToNoti = payload ["email"];

          console.log ("fullName : " + fullName);
          console.log ("prefixCode : " + prefixCode);
          console.log ("shortName : " + fullName);
          console.log ("branchId : " + branchId);
          console.log ("accountName : " + accountName);
          console.log ("accountNum : " + accountNum);
          console.log ("sortCode : " + sortCode);
          console.log ("emailsToNoti : " + emailsToNoti);

          let cashBoxValue = this.branchForm.value ["cashControl"]
          let chequeBoxValue = this.branchForm.value ["offAccountControl"]

          var cashCheStr = "";

          if (cashBoxValue) {
            cashCheStr = "CA";
          }

          if (chequeBoxValue) {
            cashCheStr = "CR";
          }

          let customerAccountNum = this.branchForm.value ["accountNumControl"];

          console.log ("cashBoxValue : " + cashBoxValue + ", chequeBoxValue : " + chequeBoxValue + ", cashCheStr : " + cashCheStr + ", customerAccountNum : " + customerAccountNum);

          console.log ("paymentIdUse : " + this.paymentIdUse + ", uuIdUse : " + this.uuIdUse);

          this.router.navigate(['/pay', {
          "fullName": fullName,
          "prefixCode": prefixCode,
          "shortName": shortName,
          "branchId":branchId,
          "accountName":accountName,
          "customerAccountNum":customerAccountNum,
          "accountNum":accountNum,
          "sortCode":sortCode,
          "cashCheStr":cashCheStr,
          "emailsToNoti":emailsToNoti,
          "amountToPay":this.amountToPay,

          "paymentId":this.paymentIdUse,
          "uuIdUse":this.uuIdUse,

          }]);

          //return allItems;

        },  (err)=> {
          console.log("probleme : ", err)
        }
      );

    }*/

  getBranchNameIdAlt (branchName) {
  }


  getBranchNameId (branchName) {

    for (const custInfo of this.custNames) {
      if (custInfo.name == branchName) {
        return custInfo.id
      }
    }

    return "";
  }

  navigateBack(){
    this.location.back();
  }

  async resetForm () {
    console.log ("resetForm")

    localStorage.setItem("bankSelected", "");
    localStorage.setItem("accountNum", "");
    localStorage.setItem("accountName", "");
    localStorage.setItem("amount", "");
    localStorage.setItem("sortCode", "");

    this.branchForm.controls['branchSelectControl'].setValue("");
    this.branchForm.controls['amountControl'].setValue("");
    this.branchForm.controls['cashControl'].setValue("");
    this.branchForm.controls['offAccountControl'].setValue("");
    this.branchForm.controls['accountNumControl'].setValue("");
  }

  async getAuthToken (callback) {

      console.log ("getAuthToken");

      let backendUrl = this.BACKEND_URL + "getAuthToken?clientSecret=IZZYSEKKER2022";
      console.log ("backendUrl : " + backendUrl);

      let headers = {
        "Content-Type" : "application/json"
      };

          try {

                  let response = await fetch(backendUrl,
                      {
                      method: 'GET',
                      headers: headers,
                      }
                    );

                  console.log ("response : " + JSON.stringify(response));

                  let responseBlob = await response.blob();

                  console.log("responseBlob : " + JSON.stringify(responseBlob));

                  const reader = new FileReader();

                  reader.addEventListener('loadend', (e) => {

                    //var responseJSON = ;

                    try {

                      console.log ("reader.result : " + reader.result);

                      let jsonData = JSON.parse (reader.result  as string);
                      console.log ("jsonData : " + jsonData)

                      if (jsonData.status == "isOK") {

                        let accessToken = jsonData.access_token;

                        console.log ("accessToken : " + accessToken);

                        callback (jsonData.status, accessToken);

                      } else {
                        callback (jsonData.status, "");
                      }


                      //responseJSON = JSON.parse (reader.result)
                    } catch (e) {
                      console.log ("jsonParseError : " + e);
                      alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                      //callback(e, []);
                      return;
                    }

                    //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                    return null;
                  });

                  reader.readAsText(responseBlob);

                } catch (error) {

                  callback ("failed", "");

                  console.log ("getMe error : " + error);

                  //return error

                }

    }

  async getBranchByNameWithAuth (branchName, currentTry, callback) {

      let accessToken = localStorage.getItem ("access_token")

          console.log ("accessToken : " + accessToken);

          if (accessToken == null) {

            this.getAuthToken ((status, accessToken) => {
              console.log ("status : " + status);
              console.log ("accessToken : " + accessToken);

              if (status == "isOK") {
                localStorage.setItem("access_token", accessToken);

                this.getBranchByNameWithAuth (branchName, 1, (branchData, getBranchStatus) => {
                  callback (branchData, getBranchStatus);
                });
              }
            });
          } else {
            this.getBranchByName (branchName, (branchData, getBranchStatus) => {
              console.log ("getBranchStatus : " + getBranchStatus);
              console.log ("branchData : " + branchData);

              if ((currentTry == 1) && (branchData == "Invalid Token")) {
                alert ("Could not get access token after second try...!, Error Code : # 1001");
                callback ({}, "notOk");
                return;
              }

              if (getBranchStatus == "getBranchStatus") {
                this.getBranchByNameWithAuth (branchName, 1, (branchDataA, getBranchStatusA) => {
                  callback (branchDataA, getBranchStatusA);
                });
              }
            })
          }
    }

  async getBranchByIdAuth (branchId, currentTry, callback) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("snapAuth : accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("gbd : accessToken null");
    }

    if (accessToken == "null") {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);

          this.getBranchByIdAuth (branchId, 1, (jsonData, paymentStatus) => {
            callback (jsonData, paymentStatus);
          });
        }
      });
    } else {
      this.getBranchById (branchId, (jsonData, paymentStatus) => {
        callback (jsonData, paymentStatus);

        if ((currentTry == 1) && (paymentStatus == "invalidToken")) {
          alert ("Could not get access token after second try...!, Error Code : # 1001");
          callback ({}, "notOk");
          return;
        }

      });
    }

  }

  async getBranchById (branchId, callback) {

        let accessToken = localStorage.getItem ("access_token");

        let backendUrl = this.BACKEND_URL + "getbranch?branchId=" + branchId;
        console.log ("branchId : " + branchId);

        let headers = {
          "x-access-token" : accessToken
        };

        try {

          let response = await fetch(backendUrl,
              {
              method: 'GET',
              headers: headers,

              }
            );

          console.log ("response : " + JSON.stringify(response));

          let responseBlob = await response.blob();

          console.log("responseBlob : " + JSON.stringify(responseBlob));

          const reader = new FileReader();

          reader.addEventListener('loadend', (e) => {

            //var responseJSON = ;

            try {

              console.log ("reader.result : " + reader.result);

              if (reader.result == "invalid token") {
                callback ({}, "invalidToken");
                return;
              }

              let jsonData = JSON.parse (reader.result  as string);


              console.log ("jsonData : " + jsonData)

              callback (jsonData, "ok");

              //responseJSON = JSON.parse (reader.result)
            } catch (e) {
              console.log ("jsonParseError : " + e);
              callback ({}, "error");

              alert ("The following error occured. Please try again\n\njsonParseError : " + e);
              //callback(e, []);
              return;
            }

            //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

            return null;
          });

          reader.readAsText(responseBlob);

        } catch (error) {

          console.log ("getMe error : " + error);
        }

      }


  async getBranchByName (branchName, callback) {

      let accessToken = localStorage.getItem ("access_token");

      let backendUrl = this.BACKEND_URL + "getbranchbyname?branchName=" + branchName;
      console.log ("backendUrl : " + backendUrl);

      let headers = {
        "x-access-token" : accessToken
      };

      try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,

            }
          );

        console.log ("response : " + JSON.stringify(response));

        let responseBlob = await response.blob();

        console.log("responseBlob : " + JSON.stringify(responseBlob));

        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {

          //var responseJSON = ;

          try {

            console.log ("reader.result : " + reader.result);

            if (reader.result == "invalid token...") {
              callback ("invalidToken");
              return;
            }


            let jsonData = JSON.parse (reader.result  as string);


            console.log ("jsonData : " + jsonData)

            callback (jsonData, "ok");

            //responseJSON = JSON.parse (reader.result)
          } catch (e) {
            console.log ("jsonParseError : " + e);
            callback ({}, "error");

            alert ("The following error occured. Please try again\n\njsonParseError : " + e);
            //callback(e, []);
            return;
          }

          //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

          return null;
        });

        reader.readAsText(responseBlob);

      } catch (error) {

        console.log ("getMe error : " + error);
      }

    }

  async getDetailsFromBackend (uuid, sender, paymentUid, amount) {

  console.log ("uuid : " + uuid);
  console.log ("sender : " + sender);
  console.log ("paymentId : " + paymentUid);

  console.log ("storing details!");
  //localStorage.setItem("paymentIdz", paymentUid);
  //localStorage.setItem("uuIdUse", uuid);

  console.log ("details stored : " + localStorage.getItem("paymentIdz"));

  this.isLoading = true;

    console.log ("getting payment details");

    //this.getPaymentDetailsFromFirebase (paymentUid, userUid);
    //this.getPaymentDetailsFromFirebaseSnap (paymentUid, userUid);
    await this.getPaymentDetailsFromBackendSnapAuth (paymentUid, uuid, 0, (jsonData, paymentStatus) => {

      console.log ("jsonData : " + JSON.stringify(jsonData));
      console.log ("paymentStatus : " + paymentStatus);

      this.isLoading = false;

      if (paymentStatus != "ok") {
        alert ("Could not find payment " + paymentUid + " for user " + uuid);
        return;
      }

      let custAccountNumber = jsonData ["accountNumber"];
      let amount = jsonData ["amountPay"];
      let branchId = jsonData ["customerId"];
      let branchName = jsonData ["customerName"];
      let accountName = jsonData ["destAccountName"];
      let accountNum = jsonData ["destAccountNum"];
      let emailsToNoti = jsonData ["destEmail"];
      let sortCode = jsonData ["destSortCode"];

      if (branchName != null) {
        console.log ("setBranch");
      }

      var amountNice = amount;


      if (amount != undefined) {
        amountNice = amount.replace("£", "")
      }

      this.branchIdUse = branchId;
      console.log ("this.branchIdUse : " + this.branchIdUse);


      console.log ("amount : " + amount);
      console.log ("custAccountNumber : " + custAccountNumber);

      var referenceUse = branchId.substring(0, 2) + " EP" + custAccountNumber;

      this.makeTokenRequestWithAuthToken (amountNice, sortCode, accountNum, accountName, referenceUse, 1)

    });


  }


  async makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, currentTry) {

      let accessToken = localStorage.getItem ("access_token")

      console.log ("token req accessToken : " + accessToken);

      if (accessToken == "null") {
        console.log ("accessToken str");
      }

      this.isLoading = true;

      if ((accessToken == null) || (accessToken == "null")) {

        this.getAuthToken ((status, accessToken) => {
          console.log ("status : " + status);
          console.log ("got accessToken : " + accessToken);

          if (status == "isOK") {
            localStorage.setItem("access_token", accessToken);


            this.makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 1);
          }
        });
      } else {

        await this.makeTokenRequest (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, (moreJsonData, morePaymentStatus) => {

          console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
          console.log ("morePaymentStatus : " + morePaymentStatus);

          if (morePaymentStatus == "invalidToken") {

            if (currentTry == 1) {
              alert ("Could not get access token after second try...!, Error Code : # 1002");
              return;
            }

            this.makeTokenRequestWithAuthToken (amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 1);
            return;

          }

          let statusCode = moreJsonData.statusCode

          console.log ("statusCode : " + statusCode);

          if (statusCode == "200") {
            let requestId = moreJsonData.requestId
            console.log ("requestId : " + requestId);

            //https://web-app.sandbox.token.io/app/request-token/rq:41rDvoe7GswaieMg215cLq6SJDPB:5zKtXEAq

            let tokenUrl = Constants.TOKEN_REQUEST_URL + requestId;

            console.log ("tokenUrl : " + tokenUrl);

            window.location.href = tokenUrl;
          }

        });

      }

    }

    async makeTokenRequest (amountStr, destSortCode, destAccountNum, destAccountName, paymentDesr, callback) {

      let accessToken = localStorage.getItem ("access_token");

      let backendUrl = this.BACKEND_URL + "makeTokenTransfer";
      console.log ("backendUrl2 : " + backendUrl);

      let headers = {
        "x-access-token" : accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };

      let paymentDetails = {
          "amountStr" : amountStr,
          "destSortCode" : destSortCode,
          "destAccountNum" : destAccountNum,
          "destAccountName" : destAccountName,
          "paymentDescr" : paymentDesr,
   //       "redirectUrl" : "https://192.168.0.150/",
          "redirectUrl" : "https://95.179.225.6/",


      }

      console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

      try {

          let response = await fetch(backendUrl,
              {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(paymentDetails)
              }
            );

            console.log ("response : " + JSON.stringify(response));
            this.isLoading = false;

                  let responseBlob = await response.blob();

                  console.log("responseBlob : " + JSON.stringify(responseBlob));

                  const reader = new FileReader();

                  reader.addEventListener('loadend', (e) => {

                    //var responseJSON = ;


                    try {

                      console.log ("payment detail reader.result : " + reader.result);

                      if (reader.result == "invalid token...") {
                        callback ("invalidToken");
                        return;
                      }

                      let jsonData = JSON.parse (reader.result  as string);

                      console.log ("jsonData : " + jsonData)

                      callback (jsonData, "ok");

                      //responseJSON = JSON.parse (reader.result)
                    } catch (e) {
                      console.log ("jsonParseError : " + e);

                      //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                      callback ("error");

                      //callback(e, []);
                      return;
                    }

                    return null;
                  });

                  reader.readAsText(responseBlob);



      } catch (error) {
          console.log ("getMe error : " + error);
      }

    }


}


