import Constants from './Constants'

export default class APIManager {

  // call back function for redeemToken
  async redeemToken (tokenId, callback) {

    console.log ("redeemToken : tokenId : " + tokenId);

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = Constants.BACKEND_URL + "redeemToken";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    let tokenDetails = {
      "tokenId" : tokenId
    };

    console.log ("tokenDetails : " + JSON.stringify(tokenDetails));

    try {

        let response = await fetch(backendUrl,
            {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(tokenDetails)
            }
          );

          console.log ("response : " + JSON.stringify(response));
          //this.isLoading = false;

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("redeem tokens detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }


  }

  // call back function for transfer status
  async getTransferStatus (transferId, callback) {

    console.log ("***** getTransferStatus : transferId : " + transferId);

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = Constants.BACKEND_URL + "queryTokenStatus?transferId=" + transferId;
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,
            }
          );

          console.log ("response : " + JSON.stringify(response));
          //this.isLoading = false;

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("payment detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }


  }

  async makeTokenRequest (amountStr, destSortCode, destAccountNum, destAccountName, paymentDesr, subTppId, callback) {

      console.log ("***** makeTokenRequest");

      let accessToken = localStorage.getItem ("access_token");

      let backendUrl = Constants.BACKEND_URL + "makeTokenTransfer";
      console.log ("backendUrl1 : " + backendUrl);

      let headers = {
        "x-access-token" : accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };

      let paymentDetails = {
          "amountStr" : amountStr,
          "destSortCode" : destSortCode,
          "destAccountNum" : destAccountNum,
          "destAccountName" : destAccountName,
          "paymentDescr" : paymentDesr,
   //       "redirectUrl" : "https://192.168.0.150/",
          "redirectUrl" : Constants.CALLBACK_URL,
          "subTppId" : subTppId,

      }

      console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

      try {

          let response = await fetch(backendUrl,
              {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(paymentDetails)
              }
            );

            console.log ("response : " + JSON.stringify(response));
            //this.isLoading = false;

                  let responseBlob = await response.blob();

                  console.log("responseBlob : " + JSON.stringify(responseBlob));

                  const reader = new FileReader();

                  reader.addEventListener('loadend', (e) => {

                    //var responseJSON = ;


                    try {

                      console.log ("payment detail reader.result : " + reader.result);

                      if (reader.result == "invalid token...") {
                        callback ("invalidToken");
                        return;
                      }

                      let jsonData = JSON.parse (reader.result  as string);

                      console.log ("jsonData : " + jsonData)

                      callback (jsonData, "ok");

                      //responseJSON = JSON.parse (reader.result)
                    } catch (e) {
                      console.log ("jsonParseError : " + e);

                      //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                      callback ("error");

                      //callback(e, []);
                      return;
                    }

                    return null;
                  });

                  reader.readAsText(responseBlob);



      } catch (error) {
          console.log ("getMe error : " + error);
      }

    }

  getErrorCodeReaction (tokenErrorStr) {

    var tokenErrorCodes = [
      "PENDING",
      "SUCCESS",
      "FAILURE_INSUFFICIENT_FUNDS",
      "FAILURE_INVALID_CURRENCY",
      "FAILURE_GENERIC",
      "FAILURE_PERMISSION_DENIED",
      "PROCESSING",
      "FAILURE_CANCELED",
      "FAILURE_QUOTE_EXPIRED",
      "FAILURE_INVALID_AMOUNT",
      "FAILURE_INVALID_QUOTE",
      "FAILURE_EXPIRED",
      "PENDING_EXTERNAL_AUTHORIZATION",
      "SENT",
      "INITIATED",
      "FAILURE_DECLINED",
      "STATUS_NOT_AVAILABLE",
      "REJECTED",
      "NOT FOUND",
      "INTERNAL",
  ]

    var appText = [
      "Pending",
      "Success",
      "Failed",
      "Failed",
      "Failed",
      "Failed",
      "Pending",
      "Failed",
      "Failed",
      "Failed",
      "Failed",
      "Failed",
      "Pending",
      "Pending",
      "Pending",
      "Failed",
      "Pending",
      "Failed",
      "Failed",
      "Pending",
      "Failed",
      "Failed",
  ]

    var reasonStr = [
    "Transaction has been submitted and is pending bank action",
    "Transaction has been successfully executed",
    "Transaction failed due to insufficient funds in debtor account (\"NSF\")",
    "Transaction failed due to a an unsupported currency or currency mismatch",
    "Transaction failed due to reasons other than those included in this list",
    "Transaction failed due to an access violation",
    "Transaction is being processed by the bank",
    "Transaction failed due to cancellation/roll back by the user",
    "Transaction failed due to expiration of the quoted exchange rate",
    "Transaction failed due to an invalid/unsupported amount",
    "Transaction failed due to an invalid exchange rate",
    "Transaction failed to complete within the allotted time",
    "Transaction requires authorisation by the user-accountholder to complete",
    "Transaction has been submitted but has not been acknowledged by the bank",
    "Transaction has been initiated",
    "Transaction is rejected due to the state of the user account (\"Account Closed\" or \"Account Locked\")",
    "Transfer status cannot be determined",
    "",
    "",
    "",
    "",
    "",
    ]

    var emojiStr = [
      "⌛",
      "😊",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "🙁",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
      "❌",
    ]

    var longTextStr = [
      "This payment needs further bank action and has not completed",
      "This payment has completed",
      "This payment has failed due to lack of funds in the chosen account",
      "This payment has failed",
      "This payment has failed",
      "This payment has failed",
      "This payment needs further bank action and has not completed",
      "This payment has failed",
      "This payment has failed",
      "This payment has failed",
      "This payment has failed",
      "This payment has taken too long to complete and has expired",
      "This payment requires further authorisation by the payer.",
      "This payment needs further bank action and has not completed",
      "This payment needs further bank action and has not completed",
      "This payment has failed as the chosen account is unable to make payments",
      "This payment needs further bank action and has not completed",
      "This payment has been rejected by the bank",
      "This payment has been rejected by the bank",
      "This payment needs further bank action and has not completed",
      "This payment has taken too long to complete and has expired",
      "An Internal Error has occured. Please contact EchoPay Support",
    ]

    var idxFound = -1;

    for (let i = 0; i < tokenErrorCodes.length; i++) {

      if (tokenErrorStr == tokenErrorCodes[i]) {
        console.log ("found error as : " + tokenErrorCodes[i]);
        idxFound = i;
      }
    }

    console.log ("idxFound : " + idxFound);

    console.log ("appText : " + appText [idxFound]);

    if (idxFound == -1) {
      return [
        tokenErrorStr,
        "Unknown error detected.",
        "❌",
        ""
      ]
    }

    let retArr = [
      appText [idxFound],
      reasonStr [idxFound],
      emojiStr [idxFound],
      longTextStr [idxFound],
    ]

    console.log ("retArr : " + retArr);

    return retArr

  }

  async sendSlackNotificationWithAuth (emailAddress, status, recip, amount, refstr, currentTry) {

    let accessToken = localStorage.getItem ("access_token")

    console.log ("token req accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("accessToken str");
    }


    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("got accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);


          this.sendSlackNotificationWithAuth (emailAddress, status, recip, amount, refstr, 1);
        }
      });
    } else {

      await this.sendSlackNotification (emailAddress, status, recip, amount, refstr, (moreJsonData, morePaymentStatus) => {

        console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
        console.log ("morePaymentStatus : " + morePaymentStatus);

        if (morePaymentStatus == "invalidToken") {

          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1002");
            return;
          }

          this.sendSlackNotificationWithAuth (emailAddress, status, recip, amount, refstr, 1);
          return;

        }

        let statusCode = moreJsonData.statusCode

        console.log ("statusCode : " + statusCode);

        if (statusCode == "200") {
          let requestId = moreJsonData.requestId
          console.log ("requestId : " + requestId);

          //https://web-app.sandbox.token.io/app/request-token/rq:41rDvoe7GswaieMg215cLq6SJDPB:5zKtXEAq

          let tokenUrl = Constants.TOKEN_REQUEST_URL + requestId;

          console.log ("tokenUrl : " + tokenUrl);

          window.location.href = tokenUrl;
        }

      });

    }

  }

  async sendSlackNotification (emailAddress, status, recip, amount, refstr, callback) {

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = Constants.BACKEND_URL + "makeSlackNotification";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      'Content-Type': 'application/json',
      "x-access-token" : accessToken,
    };

    let summaryStr = "Payment for " + amount + " to " + recip + ". Status : " + status + " Reference : " + refstr;



    let paymentDetails = {
        "emailAddress" : emailAddress,
        "status" : status,
        "amount" : amount,
        "refstr" : refstr,
        "recip" : recip
    }

        console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

        try {

            let response = await fetch(backendUrl,
                {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(paymentDetails)
                }
              );

              console.log ("response : " + JSON.stringify(response));
              //this.isLoading = false;

                    let responseBlob = await response.blob();

                    console.log("responseBlob : " + JSON.stringify(responseBlob));

                    const reader = new FileReader();

                    reader.addEventListener('loadend', (e) => {

                      //var responseJSON = ;


                      try {

                        console.log ("payment detail reader.result : " + reader.result);

                        let jsonData = JSON.parse (reader.result  as string);

                        console.log ("jsonData : " + jsonData)

                        //responseJSON = JSON.parse (reader.result)
                      } catch (e) {
                        console.log ("jsonParseError : " + e);

                        //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                        //callback(e, []);
                        return;
                      }

                      return null;
                    });

                    reader.readAsText(responseBlob);



        } catch (error) {
            console.log ("getMe error : " + error);
        }

  }

  async sendDiscordNotification (status, merchant, amount, wholesaleAccountNumber, statusReasonInformation, bankId, callback) {

    console.log ("***** sendDiscordNotification");

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = Constants.BACKEND_URL + "makeDiscordNotification";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      'Content-Type': 'application/json',
      "x-access-token" : accessToken,
    };

    let paymentDetails = {
        "status" : status,
        "merchant" : merchant,
        "amount" : amount,
        "wholesaleAccountNumber" : wholesaleAccountNumber,
        "statusReasonInformation" : statusReasonInformation,
        "bankId" : bankId
    }

        console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

        try {

            let response = await fetch(backendUrl,
                {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(paymentDetails)
                }
              );

              console.log ("response : " + JSON.stringify(response));
              //this.isLoading = false;

                    let responseBlob = await response.blob();

                    console.log("responseBlob : " + JSON.stringify(responseBlob));

                    const reader = new FileReader();

                    reader.addEventListener('loadend', (e) => {

                      //var responseJSON = ;


                      try {

                        console.log ("payment detail reader.result : " + reader.result);

                        let jsonData = JSON.parse (reader.result  as string);

                        console.log ("jsonData : " + jsonData)

                        //responseJSON = JSON.parse (reader.result)
                      } catch (e) {
                        console.log ("jsonParseError : " + e);

                        //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                        //callback(e, []);
                        return;
                      }

                      return null;
                    });

                    reader.readAsText(responseBlob);



        } catch (error) {
            console.log ("getMe error : " + error);
        }
  }


  async sendEmailNotification (emailAddress, status, recip, amount, refstr) {
    console.log ("emailAddressz : " + emailAddress);

    var queryStr = "dest=" + emailAddress;
    queryStr = queryStr + "&recip=" + recip;
    queryStr = queryStr + "&amount=" + amount;
    queryStr = queryStr + "&status=" + status;
    queryStr = queryStr + "&refstr=" + refstr;

    let url = "https://us-central1-echopay-c70b9.cloudfunctions.net/emailSender?" + queryStr;

    try {

          let response = await fetch(url,
              {
              method: 'GET',
              mode: 'no-cors'
              }
            );

          console.log ("response : " + JSON.stringify(response));

          let responseBlob = await response.blob();

          console.log("responseBlob : " + JSON.stringify(responseBlob));

          const reader = new FileReader();

          reader.addEventListener('loadend', (e) => {

            console.log ("reader.result : " + reader.result);

            return null;
          });

          reader.readAsText(responseBlob);

        } catch (error) {

          console.log ("send email error : " + error);
        }

  }

  async getAuthToken (callback) {

    console.log ("**** getAuthToken");



    let backendUrl = Constants.BACKEND_URL + "getAuthToken?clientSecret=IZZYSEKKER2022";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "Content-Type" : "application/json"
    };

        try {

                let response = await fetch(backendUrl,
                    {
                    method: 'GET',
                    headers: headers,
                    }
                  );

                console.log ("response : " + JSON.stringify(response));

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;

                  try {

                    console.log ("reader.result : " + reader.result);

                    let jsonData = JSON.parse (reader.result  as string);
                    console.log ("jsonData : " + jsonData)

                    if (jsonData.status == "isOK") {

                      let accessToken = jsonData.access_token;

                      console.log ("accessToken : " + accessToken);

                      callback (jsonData.status, accessToken);

                    } else {
                      callback (jsonData.status, "");
                    }


                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);
                    alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                    //callback(e, []);
                    return;
                  }

                  //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                  return null;
                });

                reader.readAsText(responseBlob);

              } catch (error) {

                callback ("failed", "");

                console.log ("getMe error : " + error);

                //return error

              }

  }


  async getBranchByIdAuth (branchId, currentTry, callback) {

    console.log ("***** getBrnachByIdAuth");


    let accessToken = localStorage.getItem ("access_token")

    console.log ("snapAuth : accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("gbd : accessToken null");
    }

    if (accessToken == "null") {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);

          this.getBranchByIdAuth (branchId, 1, (jsonData, paymentStatus) => {
            callback (jsonData, paymentStatus);
          });
        }
      });
    } else {
      this.getBranchById (branchId, (jsonData, paymentStatus) => {
        callback (jsonData, paymentStatus);

        if ((currentTry == 1) && (paymentStatus == "invalidToken")) {
          alert ("Could not get access token after second try...!, Error Code : # 1001");
          callback ({}, "notOk");
          return;
        }

      });
    }

  }


  async getBranchById (branchId, callback) {

    console.log ("***** getBranchById");


    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = Constants.BACKEND_URL + "getbranch?branchId=" + branchId;
    console.log ("branchId : " + branchId);

    let headers = {
      "x-access-token" : accessToken
    };

    try {

      let response = await fetch(backendUrl,
          {
          method: 'GET',
          headers: headers,

          }
        );

      console.log ("response : " + JSON.stringify(response));

      let responseBlob = await response.blob();

      console.log("responseBlob : " + JSON.stringify(responseBlob));

      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {

        //var responseJSON = ;

        try {

          console.log ("reader.result : " + reader.result);

          if (reader.result == "invalid token") {
            callback ({}, "invalidToken");
            return;
          }

          let jsonData = JSON.parse (reader.result  as string);


          console.log ("jsonData : " + jsonData)

          callback (jsonData, "ok");

          //responseJSON = JSON.parse (reader.result)
        } catch (e) {
          console.log ("jsonParseError : " + e);
          callback ({}, "error");

          alert ("The following error occured. Please try again\n\njsonParseError : " + e);
          //callback(e, []);
          return;
        }

        //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

        return null;
      });

      reader.readAsText(responseBlob);

    } catch (error) {

      console.log ("getMe error : " + error);
    }

  }



}
