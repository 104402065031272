
export default class Constants {

  //static BACKEND_URL = "https://talkingcucumber.info/";
  static BACKEND_URL = "https://ep7b.com/";
  //static BACKEND_URL = "https://192.168.0.150:3001/";

  //static CALLBACK_URL = "https://95.179.225.6/";
  //static CALLBACK_URL = "https://192.168.0.150/";
  static CALLBACK_URL = "https://web.click-to-pay.co.uk/";
  //static CALLBACK_URL = "https://78.141.194.67:3001/";

  //static TOKEN_REQUEST_URL = "https://web-app.sandbox.token.io/app/request-token/";
  static TOKEN_REQUEST_URL = "https://web-app.token.io/app/request-token/";

}
