import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from './session.service';
import { CommonModule, CurrencyPipe} from '@angular/common';
import { YapilyService } from './yapily.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import JSConfetti from 'js-confetti'
import { NgxSpinnerService } from 'ngx-spinner';

import Constants from '../shared/Constants'
import APIManager from '../shared/APIManager'

import {FormControl, FormGroup, FormBuilder, FormGroupDirective, NgForm, Validators} from '@angular/forms';

import {PaymentCompleteDialog, PaymentFailedDialog} from './paymentform/paymentform.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'EchoPayWeb';

  USE_TOKEN = true;

  typeSelected: string;

  public clicked = false;

  BACKEND_URL = Constants.BACKEND_URL

  //isLoading = false;

  epUuid: string;

  branchIdUse: string
  tkRequestId: string;
  tkTokenId: string;
  tkTransferUd: string;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private currencyPipe : CurrencyPipe,
    public dialog: MatDialog,
    private renderer2: Renderer2,
    private spinnerService: NgxSpinnerService,
    private elementRef: ElementRef,
    private yapilyService: YapilyService
  ) { }

  async ngOnInit() {

//    this.showSuccessDialog ();
//    this.showErrorDialog ("PENDINGZ");

    this.typeSelected = 'ball-beat';

    let queryString = window.location.search;
    console.log ("queryString : " + queryString);

    let urlParams = new URLSearchParams(queryString);
    console.log ("urlParams : " + urlParams);

    let consentIdTmp = urlParams.get('consent')
    let innyIdTmp = urlParams.get('institution')

    localStorage.setItem("access_token", null);

    // token
    let tkError = urlParams.get('error')
    let tkMessage = urlParams.get('message')
    this.tkRequestId = urlParams.get('request-id')
    let tkTraceId = urlParams.get('trace-id')

    this.tkTokenId = urlParams.get('tokenId');


    // error, message and traceId are normailly null. They
    // will be populatoed if there's an error.
    console.log ("tkError : " + tkError);
    console.log ("tkMessage : " + tkMessage);
    console.log ("tkRequestId : " + this.tkRequestId);
    console.log ("tkTraceId : " + tkTraceId);

    console.log ("tkTokenId : " + this.tkTokenId);

    /*
    if (tkRequestId != null) {
        console.log ("isToken");


        // token payment. Need to navigate to pay
        this.router.navigate(['/pay', {
          "tkRId" : tkRequestId,
          "tkM" : tkMessage,
          "tkTrId" : tkTraceId,
          "tkE" : tkError,
         }]);
    }
    */


    // token
    if (this.tkRequestId != null) {

      console.log ("tkError : " + tkError);

      if (tkError != null) {
        console.log ("tokenError is null");

        console.log ("tokenError...!");

        //alert ("Token Error is : " + tkMessage);

        // show message here
        this.showErrorDialog (tkMessage);

        return;
      }

      /*
      if (tkError == "null") {
        console.log ("tokenError is null string");
      } else {
        console.log ("tokenError...!");

        // show message here
        return;
      }*/
    }

    if (this.tkRequestId != null) {
      console.log ("dealingWithToken");

      console.log ("paymentId : " + localStorage.getItem("paymentIdz"));



      this.checkTokenPayment ();
      return;
    }


    let inSr = urlParams.get('insr')
    let inCn = urlParams.get('incn')
    let inIv = urlParams.get('iniv')
    let inIa = urlParams.get('inia')

    let inUu = urlParams.get('inuu')
    let inPi = urlParams.get('inpi')


    let inAcc = urlParams.get('inacc')
    let inStc = urlParams.get('instc')
    let inAm = urlParams.get('inam')
    let inNm = urlParams.get('innm')

    if ((inSr != null) && (inUu != null) && (inPi != null) && (inAm != null)) {

      if ((localStorage.getItem ("didClean") == null) || (localStorage.getItem ("didClean") == "null")) {

        localStorage.setItem("uuIdUse", "");
        localStorage.setItem("paymentIdz", "");
        localStorage.setItem("error_code", "");

        localStorage.setItem("bankSelected", "");
        localStorage.setItem("accountNum", "");
        localStorage.setItem("accountName", "");
        localStorage.setItem("amount", "");
        localStorage.setItem("sortCode", "");
        localStorage.setItem("emailsToNoti", "");
        localStorage.setItem("reference", "");

        localStorage.setItem("didClean", "yes");
      }

/*

*/

    }

    console.log ("inSr : " + inSr);
    console.log ("inUu : " + inUu);
    console.log ("inPi : " + inPi);
    console.log ("inAm : " + inAm);


    console.log ("consentIdTmp : " + consentIdTmp);
    console.log ("innyIdTmp : " + innyIdTmp);

    if ((inSr != null) && (inUu != null) && (inPi != null) && (inAm != null)) {

      if (this.USE_TOKEN) {

        localStorage.setItem("paymentIdz", inPi);
        localStorage.setItem("uuIdUse", inUu);

        console.log ("stored early!");

        this.getDetailsFromBackend (inUu, inSr, inPi, inAm);
        return;

      } else {

        console.log ("goto to confirmbranch");


        this.router.navigate(['/confirmbranch', {
          "insr" : inSr,
          "userUid" : inUu,
          "paymentUid" : inPi,
          "inia" : inIa,
         }]);
      }

    }

    if ((inSr != null) && (inCn != null) && (inIv != null) && (inIa != null)) {
      this.router.navigate(['/confirmbranch', {
        "insr" : inSr,
        "incn" : inCn,
        "iniv" : inIv,
        "inia" : inIa,
       }]);
    }

    if ((inAcc != null) && (inStc != null) && (inAm != null) && (inNm != null)) {
      this.router.navigate(['/pay', {
        "inacc" : inAcc,
        "instc" : inStc,
        "inam" : inAm,
        "innm" : inNm,
       }]);
    }


    if ((consentIdTmp != null) && (innyIdTmp != null)) {

      console.log ("goto to pay");

      this.router.navigate(['/pay', {
        "consent" : consentIdTmp,
        "institution" : innyIdTmp,
       }]);
    }

  }

  clearLocalStorage () {

    console.log ("clearLocalStorage");

    localStorage.clear()
  }

  showSuccessDialog () {
    const dialogRef = this.dialog.open(PaymentCompleteDialog, {
      width: '250px',
      data: {}
    });

    this.resetForm ();

    localStorage.setItem("error_code", "SUCCESS");
    this.router.navigate(['/thankyou', {
     }]);

    /*
    dialogRef.afterClosed().subscribe(result => {
       console.log ("sending notification");

       this.router.navigate(['/thankyou', {
       }]);
    });*/
  }

  showErrorDialog (errorCodeStr) {

    //let errorCodeStr = "STATUS_NOT_AVAILABLE";

    console.log ("errorCodeStr : " + errorCodeStr);

    localStorage.setItem("error_code", errorCodeStr);
    this.router.navigate(['/thankyou', {
     }]);


     /*
    let apiManager = new APIManager ();

    let errorStrs = apiManager.getErrorCodeReaction (errorCodeStr)

    console.log ("errorStrs : " + errorStrs);

    let dataUse = {
      "appTextStr" : errorStrs[0],
      "reasonStr" : errorStrs[1],
      "emojiStr" : errorStrs[2],
      "LongTextStr" : errorStrs[3],

    }

    const dialogRef = this.dialog.open(PaymentFailedDialog, {
      width: '250px',
      data: dataUse
    });

    this.resetForm ();

    dialogRef.afterClosed().subscribe(result => {
    });
    */

  }

  public surprise(): void {

    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti({
    confettiColors: [
    '#ff8d800', '#000000', '#ffd800', '#000000', '#ffd800', '#000000',
    ],
    })

    this.clicked = true;
  }

  async getDetailsFromBackend (uuid, sender, paymentUid, amount) {


    console.log ("**** getDetailsFromBackend");

    console.log ("uuid : " + uuid);
    console.log ("sender : " + sender);
    console.log ("paymentId : " + paymentUid);

    console.log ("storing details!");
    //localStorage.setItem("paymentIdz", paymentUid);
    //localStorage.setItem("uuIdUse", uuid);

    console.log ("details stored : " + localStorage.getItem("paymentIdz"));

    this.spinnerService.show();

      console.log ("getting payment details");

      //this.getPaymentDetailsFromFirebase (paymentUid, userUid);
      //this.getPaymentDetailsFromFirebaseSnap (paymentUid, userUid);
      await this.getPaymentDetailsFromBackendSnapAuth (paymentUid, uuid, 0, (jsonData, paymentStatus) => {

        console.log ("jsonData : " + JSON.stringify(jsonData));
        console.log ("paymentStatus : " + paymentStatus);

        this.spinnerService.hide();

        if (paymentStatus != "ok") {
          alert ("Could not find payment " + paymentUid + " for user " + uuid);
          return;
        }

        let custAccountNumber = jsonData ["accountNumber"];
        let amount = jsonData ["amountPay"];
        let branchId = jsonData ["customerId"];
        let branchName = jsonData ["customerName"];
        let accountName = jsonData ["destAccountName"];
        let accountNum = jsonData ["destAccountNum"];
        let emailsToNoti = jsonData ["destEmail"];
        let sortCode = jsonData ["destSortCode"];
        let subTppId = jsonData ["subTppId"];

        let paymentRef = jsonData ["paymentRef"];

        console.log ("paymentId : " + localStorage.getItem("paymentIdz"));

        let targetPaymentId = localStorage.getItem("paymentIdz");

        console.log ("subTppId : " + subTppId);
        console.log ("paymentRef : " + paymentRef);

        if (branchName != null) {
          console.log ("setBranch");
        }

        var amountNice = amount;


        if (amount != undefined) {
          amountNice = amount.replace("£", "")
        }

        this.branchIdUse = branchId;
        console.log ("this.branchIdUse : " + this.branchIdUse);


        console.log ("amount : " + amount);
        console.log ("custAccountNumber : " + custAccountNumber);

//        var referenceUse = branchId.substring(0, 2) + " EP" + custAccountNumber;
       //var referenceUse = branchId.substring(0, 2) + " EPClover";
        var referenceUse = paymentRef;

        this.makeTokenRequestWithAuthToken (targetPaymentId, paymentUid, uuid, amountNice, sortCode, accountNum, accountName, referenceUse, subTppId, 1)

        //this.getCustomerDetailsAndMakeTokenRequest (branchId, amountNice, sortCode, accountNum, accountName);

      });


    }

  async getCustomerDetailsAndMakeTokenRequest (customerId, amountNice, sortCode, accountNum, accountName) {

    console.log ("**** getCustomerDetailsAndMakeTokenRequest");

    let apiManager = new APIManager ();

    await apiManager.getBranchByIdAuth (customerId, 0, (jsonData, paymentStatus) => {

    console.log ("jsonData : " + JSON.stringify(jsonData));
    console.log ("paymentStatus : " + paymentStatus);

    if (paymentStatus != "ok") {
      alert ("Could not find branch for id : " + customerId)
      return;
    }

    let fullName = jsonData ["custName"];
    let prefixCode = jsonData ["custInvoiceRef"];
    let shortName = jsonData ["custInvoiceRef"];
    let branchId = jsonData ["customerId"];
    let accountName = jsonData ["destAccountName"];
    let accountNum = jsonData ["destAccountNum"];
    let sortCode = jsonData ["destSortCode"];
    let emailsToNoti = jsonData ["email"];

    console.log ("fullName : " + fullName);
    console.log ("prefixCode : " + prefixCode);
    console.log ("shortName : " + fullName);
    console.log ("branchId : " + branchId);
    console.log ("accountName : " + accountName);
    console.log ("accountNum : " + accountNum);
    console.log ("sortCode : " + sortCode);
    console.log ("emailsToNoti : " + emailsToNoti);
    });


  }

  async getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, currentTry, callback) {

    console.log ("**** getPaymentDetailsFromBackendSnapAuth");

    let accessToken = localStorage.getItem ("access_token")

      console.log ("snapAuth : accessToken : " + accessToken);

      if (accessToken == "null") {
        console.log ("gpd : accessToken null");
      }

      /*
      if (accessToken == undefined) {
        console.log ("accessToken undefined");
      }

      if (accessToken == "") {
        console.log ("accessToken empty");
      }*/

      if (accessToken == "null") {

        this.getAuthToken ((status, accessToken) => {
          console.log ("status : " + status);
          console.log ("accessToken : " + accessToken);

          if (status == "isOK") {
            localStorage.setItem("access_token", accessToken);

            this.getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, 1, (jsonData, paymentStatus) => {

              console.log ("firstReturn");

              callback (jsonData, paymentStatus);
            });
          }
        });
      } else {

        this.getPaymentDetailsFromBackendSnap (paymentUid, userUid, (jsonData, paymentStatus) => {
          callback (jsonData, paymentStatus);

          console.log ("a paymentStatus : " + paymentStatus);
          console.log ("a jsonData : " + jsonData);

          if ((currentTry == 1) && (paymentStatus == "invalidToken")) {
            alert ("Could not get access token after second try...!, Error Code : # 1001");
            callback ({}, "notOk");
            return;
          }

          if (paymentStatus == "invalidToken") {

            this.getPaymentDetailsFromBackendSnapAuth (paymentUid, userUid, 1, (jsonData, paymentStatus) => {

              console.log ("firstReturn");

              callback (jsonData, paymentStatus);
            });

            return;
          }

          callback (jsonData, paymentStatus);

        });

      }

  }

  async getAuthToken (callback) {

    console.log ("getAuthToken");

    let backendUrl = this.BACKEND_URL + "getAuthToken?clientSecret=IZZYSEKKER2022";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "Content-Type" : "application/json"
    };

        try {

                let response = await fetch(backendUrl,
                    {
                    method: 'GET',
                    headers: headers,
                    }
                  );

                console.log ("response : " + JSON.stringify(response));

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;

                  try {

                    console.log ("reader.result : " + reader.result);

                    let jsonData = JSON.parse (reader.result  as string);
                    console.log ("jsonData : " + jsonData)

                    if (jsonData.status == "isOK") {

                      let accessToken = jsonData.access_token;

                      console.log ("accessToken : " + accessToken);

                      callback (jsonData.status, accessToken);

                    } else {
                      callback (jsonData.status, "");
                    }


                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);
                    alert ("The following error occured. Please try again\n\njsonParseError : " + e);
                    //callback(e, []);
                    return;
                  }

                  //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

                  return null;
                });

                reader.readAsText(responseBlob);

              } catch (error) {

                callback ("failed", "");

                console.log ("getMe error : " + error);

                //return error

              }

  }

  async getPaymentDetailsFromBackendSnap (paymentUid, userUid, callback) {

    console.log ("**** getPaymentDetailsFromBackendSnap");

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "getuserpayment?paymentUid=" + paymentUid + "&userUid=" + userUid;
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken
    };

    console.log ("accessToken : " + accessToken);

    try {

      let response = await fetch(backendUrl,
          {
          method: 'GET',
          headers: headers,

          }
        );

      console.log ("response : " + JSON.stringify(response));

      let responseBlob = await response.blob();

      console.log("responseBlob : " + JSON.stringify(responseBlob));

      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {

        //var responseJSON = ;

        try {

          console.log ("getpayment : reader.result : " + reader.result);

          if (reader.result == "Invalid Token") {
            callback ({}, "invalidToken");
            return;
          }


          let jsonData = JSON.parse (reader.result  as string);


          console.log ("jsonData : " + JSON.stringify(jsonData))




          callback (jsonData, "ok");

          //responseJSON = JSON.parse (reader.result)
        } catch (e) {
          console.log ("jsonParseError : " + e);
          callback ({}, "error");

          alert ("The following error occured. Please try again\n\njsonParseError : " + e);
          //callback(e, []);
          return;
        }

        //console.log ("responseJSON : " + JSON.stringify(responseJSON) );

        return null;
      });

      reader.readAsText(responseBlob);

    } catch (error) {

      console.log ("getMe error : " + error);
    }

  }

  async makeTokenRequestWithAuthToken (targetPaymentId, paymentId, uuid, amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, subTppId, currentTry) {

    console.log ("**** makeTokenRequestWithAuthToken");

    let accessToken = localStorage.getItem ("access_token")

    console.log ("token req accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("accessToken str");
    }

    this.spinnerService.show();

    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("got accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);


          this.makeTokenRequestWithAuthToken (targetPaymentId, paymentId, uuid, amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, subTppId, 1);
        }
      });
    } else {

      await this.makeTokenRequest (targetPaymentId, paymentId, uuid, amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, subTppId, (moreJsonData, morePaymentStatus) => {

        console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
        console.log ("morePaymentStatus : " + morePaymentStatus);

        if (morePaymentStatus == "invalidToken") {

          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1002");
            return;
          }

          this.makeTokenRequestWithAuthToken (targetPaymentId, paymentId, uuid, amountStr, destSortCode, destAccountNum, destAccountName, paymentDescr, 1, subTppId);
          return;

        }

        let statusCode = moreJsonData.statusCode

        console.log ("statusCode : " + statusCode);

        if (statusCode == "200") {
          let requestId = moreJsonData.requestId
          console.log ("requestId : " + requestId);

          //https://web-app.sandbox.token.io/app/request-token/rq:41rDvoe7GswaieMg215cLq6SJDPB:5zKtXEAq

          let tokenUrl = Constants.TOKEN_REQUEST_URL + requestId;

          console.log ("tokenUrl : " + tokenUrl);

          window.location.href = tokenUrl;
        }

      });

    }

  }

  async makeTokenRequest (targetPaymentId, paymentId, uuid, amountStr, destSortCode, destAccountNum, destAccountName, paymentDesr, subTppId, callback) {

    console.log ("**** makeTokenRequest : subTppId : " + subTppId);

    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "makeTokenTransfer";
    console.log ("backendUrl3 : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    let paymentDetails = {
        "amountStr" : amountStr,
        "destSortCode" : destSortCode,
        "destAccountNum" : destAccountNum,
        "destAccountName" : destAccountName,
        "paymentId" : paymentId,
        "uuid" : uuid,
        "paymentDescr" : paymentDesr,
 //       "redirectUrl" : "https://192.168.0.150/",
        "redirectUrl" : Constants.CALLBACK_URL,
        "subTppId" : subTppId,
        "targetPaymentId" : targetPaymentId,

    }

    console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

    localStorage.setItem("sort_code", destSortCode);
    localStorage.setItem("wh_account_num", destAccountNum);


    try {

        let response = await fetch(backendUrl,
            {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(paymentDetails)
            }
          );

          console.log ("response : " + JSON.stringify(response));
          this.spinnerService.hide();

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("payment detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }

  }

  async checkTokenPayment () {

    console.log ("**** checkTokenPayment");

    //let requestId = "rq:47zj89PZKMz13CwpkLDUsTFCpcYy:5zKtXEAq";
    let requestId = this.tkRequestId;

    await this.callCheckTokenRequest (requestId, 0, (tryNumberGot, requestStatus) => {

      console.log("gaveUpWaiting");
    });



  }

  async callCheckTokenRequest (requestId, tryNumber, callback) {

    console.log ("**** callCheckTokenRequest");

    let currentTry = tryNumber + 1;

    console.log ("currentTry : " + currentTry);

    let emailsShouldNoti = localStorage.getItem ("emailsToNoti")
    let accountName = localStorage.getItem ("accountName")

    console.log ("accountName : " + accountName);

    let amountStr = localStorage.getItem ("amount");
    let amount = this.currencyPipe.transform(amountStr, '£');
    let reference = localStorage.getItem ("reference")



    var invoiceRef = reference
    let emailsToNoti = emailsShouldNoti;

    console.log ("emailsToNoti1 : " + emailsToNoti);

    if (tryNumber < 10) {

      this.spinnerService.show();

      let paymentId = localStorage.getItem ("paymentIdz")

      await this.getTokenRequestResultWithAuthToken (requestId, paymentId, 0, (moreJsonData, morePaymentStatus) => {

          console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
          console.log ("morePaymentStatus : " + morePaymentStatus);

          let statusCode = moreJsonData.statusCode
          let transferId = moreJsonData.transferId

          this.spinnerService.hide();

          if (statusCode == "200") {
            let tokenStatus = moreJsonData.tokenStatus

            console.log ("tokenStatus : " + tokenStatus);

            var shouldRetry = false;


            let uuIdUse = localStorage.getItem ("uuIdUse")

            //let paymentRef = localStorage.getItem ("paymentRef")
            let paymentRef = "EchoClover"

            // TODO : add check here..!
            console.log ("tokenRequest : paymentId : " + paymentId + ", uuIdUse : " + uuIdUse);

            if ((tokenStatus == "PENDING") || (tokenStatus == "PROCESSING") || (tokenStatus == "pending"))  {
              shouldRetry = true;
            }

            if (tokenStatus == "EXPIRED") {
            //if (tokenStatus == "PROCESSED") {

              this.showErrorDialog (tokenStatus)

              /*
              const dialogRef = this.dialog.open(PaymentFailedDialog, {
                width: '250px',
                data: {}
              });
              */

              callback (currentTry, tokenStatus);
              return;
            }

//            if (tokenStatus == "SUCCESS") {
            if (tokenStatus == "PROCESSED") {

              console.log ("transferId : " + transferId);

              // Oct 2023, don't redeem the token if we have a
              // transferId
              if (transferId != undefined) {

                this.getTransferStatusRepeatedly (transferId, uuIdUse, paymentId, paymentRef, amount, accountName);
                return;


              } else {

                console.log ("emailsToNoti : " + emailsToNoti);

                console.log ("need to redeem token");

                this.goAndRedeemToken (this.tkTokenId, uuIdUse, paymentId, paymentRef, amount, accountName, (jsonData, tokenRedeemStatus) => {
                return;

                });

              }





/*
              this.signInToFirebaseAgain (uuIdUse, paymentId, paymentRef);

              this.surprise();

              this.resetForm ();

              localStorage.setItem("error_code", "SUCCESS");
              this.router.navigate(['/thankyou', {
               }]);
*/



              return;
            }

            if (shouldRetry) {
              let retryTimer = setTimeout(
                () => {
                console.log ("retryTimerFired");

                this.callCheckTokenRequest (requestId, currentTry, (currentTryGot, requestStatus) => {


                  console.log("gaveUpWaiting" + currentTry);
                });

               },
                10000
              )
            }

            callback (currentTry, tokenStatus);

          } else {
            callback (101, "ERROR");
          }


      });

    } else {
      callback (101, "GAVE UP");
    }




  }


  async goAndRedeemToken (tokenId, uuIdUse, paymentId, paymentRef, amount, accountName, callback) {

    console.log ("**** goAndRedeemToken");

    let apiManager = new APIManager ();

    this.spinnerService.show();

    apiManager.redeemToken (tokenId, (jsonData, redeemTokenStatus) => {

      this.spinnerService.hide();

      console.log ("jsonDataz : " + JSON.stringify(jsonData));
      console.log ("redeemTokenStatus : " + redeemTokenStatus);

      if (redeemTokenStatus == "ok") {

        let transferId = jsonData.transferId;

        console.log ("transferId : " + transferId);

        console.log ("got transferId");

        this.getTransferStatusRepeatedly (transferId, uuIdUse, paymentId, paymentRef, amount, accountName);

      } else {
        alert ("Could not redeem Token ID...!")
      }

      callback (jsonData, redeemTokenStatus);

    });
  }

  async getTransferStatusRepeatedly (transferId, uuIdUse, paymentId, paymentRef, amount, accountName,) {

    this.getTransferStatus (transferId, uuIdUse, paymentId, paymentRef, amount, accountName, 0, (transferResultStatus) => {

      console.log ("transferResultStatus : " + transferResultStatus);


    });

  }

  async getTransferStatus (transferId, uuIdUse, paymentId, paymentRef, amount, accountName, currentTry, callback) {

    console.log ("**** getTransferStatus : transferId : " + transferId);
    console.log ("**** getTransferStatus : uuIdUse : " + uuIdUse);
    console.log ("**** getTransferStatus : paymentId : " + paymentId);

    let apiManager = new APIManager ();

    this.spinnerService.show();

    let nextTry = currentTry + 1;

    apiManager.getTransferStatus (transferId, (jsonData, transferStatus) => {



      console.log ("jsonDataz : " + JSON.stringify(jsonData));
      console.log ("transferStatus : " + transferStatus);

      if (transferStatus == "ok") {
        let result = jsonData.tokenStatus;

        console.log ("result : " + result);

        if (result == "SUCCESS") {

          this.spinnerService.hide();

          console.log ("transaction was made...!");

          console.log ("paymentId : " + paymentId);
          console.log ("uuIdUse : " + uuIdUse);

          this.signInToFirebaseAgain (uuIdUse, paymentId, paymentRef);

          //this.surprise();

          this.resetForm ();

/*
          localStorage.setItem("error_code", "SUCCESS");
          this.router.navigate(['/thankyou', {
           }]);
*/

        } else {

          if (result.includes ("FAILURE")) {

            this.spinnerService.hide();

            let sortCode = localStorage.getItem ("sort_code")
            let accountNum = localStorage.getItem ("wh_account_num")

            let apiManager = new APIManager ();
            //apiManager.sendSlackNotificationWithAuth ("", result, accountName, amount, paymentRef, 0);

            apiManager.sendDiscordNotification (result, accountName, amount, accountNum, result, sortCode, (jsonData, redeemTokenStatus) => {
            });

            localStorage.setItem("error_code", result);
            this.router.navigate(['/thankyou', {
             }]);


          } else {
            if (currentTry < 5) {

              let retryTimer = setTimeout(
                () => {
                console.log ("retryTimerFired");

                this.getTransferStatus (transferId, uuIdUse, paymentId, paymentRef, amount, accountName, nextTry, (transferResultStatus) => {

                  console.log ("transferResultStatus : " + transferResultStatus);
                });


               },
                10000
              )


            } else {

              this.spinnerService.hide();

              console.log ("give up...!");

              this.resetForm ();

              let sortCode = localStorage.getItem ("sort_code")
              let accountNum = localStorage.getItem ("wh_account_num")

              let apiManager = new APIManager ();
              //apiManager.sendSlackNotificationWithAuth ("", "FAILURE_EXPIRED", accountName, amount, paymentRef, 0);

              apiManager.sendDiscordNotification ("FAILURE_EXPIRED", accountName, amount, accountNum, "FAILURE_EXPIRED", sortCode, (jsonData, redeemTokenStatus) => {
              });


              localStorage.setItem("error_code", "FAILURE_EXPIRED");
              this.router.navigate(['/thankyou', {
               }]);

            }
          }




        }

      }


    });


  }

  async getTokenRequestResult (requestId, paymentId, callback) {

    console.log ("**** getTokenRequestResult : requestId : " + requestId);

    let accessToken = localStorage.getItem ("access_token");

    console.log ("requestId : " + requestId);

    let backendUrl = this.BACKEND_URL + "getTokenTransferResult?requestId=" + requestId + "&" + paymentId;
    console.log ("backendUrlTr1 : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    try {

        let response = await fetch(backendUrl,
            {
            method: 'GET',
            headers: headers,
            }
          );

          console.log ("response : " + JSON.stringify(response));
          this.spinnerService.hide();

                let responseBlob = await response.blob();

                console.log("responseBlob : " + JSON.stringify(responseBlob));

                const reader = new FileReader();

                reader.addEventListener('loadend', (e) => {

                  //var responseJSON = ;


                  try {

                    console.log ("payment detail reader.result : " + reader.result);

                    if (reader.result == "invalid token...") {
                      callback ("invalidToken");
                      return;
                    }

                    let jsonData = JSON.parse (reader.result  as string);

                    console.log ("jsonData : " + jsonData)

                    callback (jsonData, "ok");

                    //responseJSON = JSON.parse (reader.result)
                  } catch (e) {
                    console.log ("jsonParseError : " + e);

                    //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                    callback ("error");

                    //callback(e, []);
                    return;
                  }

                  return null;
                });

                reader.readAsText(responseBlob);



    } catch (error) {
        console.log ("getMe error : " + error);
    }


  }

  async getTokenRequestResultWithAuthToken (requestId, paymentId, currentTry, callback) {

    console.log ("**** getTokenRequestResultWithAuthToken");

    let accessToken = localStorage.getItem ("access_token")

    console.log ("get token accessToken : " + accessToken);

    if (accessToken == "null") {
      console.log ("accessToken str");
    }

    if ((accessToken == null) || (accessToken == "null")) {

      this.getAuthToken ((status, accessToken) => {
        console.log ("status : " + status);
        console.log ("got accessToken : " + accessToken);

        if (status == "isOK") {
          localStorage.setItem("access_token", accessToken);


          this.getTokenRequestResultWithAuthToken (requestId, paymentId, 1, (moreJsonData, morePaymentStatus) => {

            callback (moreJsonData, morePaymentStatus);

          });
        }
      });

    } else {

      await this.getTokenRequestResult (requestId, paymentId, (moreJsonData, morePaymentStatus) => {

        if (morePaymentStatus == "invalidToken") {

          if (currentTry == 1) {
            alert ("Could not get access token after second try...!, Error Code : # 1004");

            callback ({}, "invalidToken");
            return;
          }

          this.getTokenRequestResultWithAuthToken (requestId, paymentId, 1, (evenMoreJsonData, evenMorePaymentStatus) => {

            callback (evenMoreJsonData, evenMorePaymentStatus);
            return;

          });
        }

        callback (moreJsonData, morePaymentStatus);
        return;
      })

    }
  }


  async resetForm () {
      console.log ("resetForm");

      // only for testing
      //this.sendEmailNotification ("joel@talkingcucumber.com", "complete", "recip", "10", "refstr");

  /*
      const dialogRef = this.dialog.open(PaymentCompleteDialog, {
        width: '250px',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
             console.log ("sending notification");
             this.router.navigate(['/thankyou', {
             }]);
          });*/

      localStorage.setItem("bankSelected", "");
      localStorage.setItem("accountNum", "");
      localStorage.setItem("accountName", "");
      localStorage.setItem("amount", "");
      localStorage.setItem("sortCode", "");
      localStorage.setItem("emailsToNoti", "");
      localStorage.setItem("reference", "");

      localStorage.setItem("shownFCAWarning", "false");
      localStorage.setItem("didClean", "null");
  }

  async signInToFirebaseAgain (userUid, paymentUid, paymentRefOld) {

    console.log ("**** signInToFirebaseAgain");

    /*
    let email = "joel@tc.com";
    let password = "Password1234";

    this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {

        console.log ("login success...! py");

        //this.updatePaymentInFirebase (userUid, paymentUid);
        this.getPaymentDetailsFromFirebaseSnap (paymentUid, userUid, paymentRef);

    }).catch((error) => {
      console.log ("error : " + error.message);
      window.alert(error.message)
    })*/

    await this.getPaymentDetailsFromBackendSnap (paymentUid, userUid, async (jsonData, paymentStatus) =>  {

      console.log ("jsonData : " + JSON.stringify(jsonData));
      console.log ("paymentStatus : " + paymentStatus);

      if (paymentStatus != "ok") {
        alert ("Could not find payment " + paymentUid + " for user " + userUid);
        return;
      }

      let custAccountNumber = jsonData ["accountNumber"];
      let amount = jsonData ["amountPay"];
      let branchId = jsonData ["customerId"];
      let branchName = jsonData ["customerName"];
      let accountName = jsonData ["destAccountName"];
      let accountNum = jsonData ["destAccountNum"];
      let emailsToNoti = jsonData ["destEmail"];
      let sortCode = jsonData ["destSortCode"];
      let paymentRef = jsonData ["paymentRef"];

      console.log ("accountName : " + accountName);
      console.log ("branchName : " + branchName);
      console.log ("accountNum : " + accountNum);
      console.log ("sortCode : " + sortCode);
      console.log ("emailsToNoti : " + emailsToNoti);

      console.log ("accountName : " + accountName);
      console.log ("branchName : " + branchName);
      console.log ("accountNum : " + accountNum);
      console.log ("sortCode : " + sortCode);
      console.log ("emailsToNoti : " + emailsToNoti);
      console.log ("paymentRef : " + paymentRef);

      //var referenceUse = branchId.substring(0, 2) + " EP" + custAccountNumber;
      var referenceUse = branchId.substring(0, 2) + " EPClover";

      let apiManager = new APIManager ();
      //apiManager.sendEmailNotification (emailsToNoti, "PROCESSED", accountName, amount, referenceUse)
      //apiManager.sendSlackNotificationWithAuth (emailsToNoti, "COMPLETED", accountName, amount, referenceUse, 0);

      apiManager.sendDiscordNotification ("COMPLETED", branchName, amount, accountNum, "COMPLETED", sortCode, (jsonData, redeemTokenStatus) => {
      });

      console.log ("paymentRef : " + paymentRef);

      let paymentDetails = {
        "accountNum" : custAccountNumber,
        "accountNumber" : custAccountNumber,
        "amountPay" : amount,
        "amount" : amount,
        "customerName" : branchName,
        "accountName" : accountName,
        "destEmail" : emailsToNoti,
        "destSortCode" : sortCode,
        "destAccountNumber" : accountNum,
        "paymentRef" : paymentRef,

        "userId" : userUid,
        "userPaymentUid" : paymentUid,

        "status" : "completed",
        "uuid" : userUid,
        "merchantId" : branchId,
        "depotRef" : branchName,
        "destAccountName" : accountName,
        "destAccountNum" : accountNum,
      }

      console.log ("amount : " + amount);
      console.log ("custAccountNumber : " + custAccountNumber);

      console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

      await this.makePaymentEntryBackendSnap (paymentDetails, (moreJsonData, morePaymentStatus) => {

        console.log ("moreJsonData : " + JSON.stringify(moreJsonData));
        console.log ("morePaymentStatus : " + morePaymentStatus);

        localStorage.setItem("error_code", "SUCCESS");
        this.router.navigate(['/thankyou', {
         }]);

      });

    });

  }


  async makePaymentEntryBackendSnap (paymentDetails, callback) {


    console.log ("**** makePaymentEntryBackendSnap");


    let accessToken = localStorage.getItem ("access_token");

    let backendUrl = this.BACKEND_URL + "makepaymententry";
    console.log ("backendUrl : " + backendUrl);

    let headers = {
      "x-access-token" : accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    console.log ("paymentDetails : " + JSON.stringify(paymentDetails));

    try {

      let response = await fetch(backendUrl,
          {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(paymentDetails)
          }
        );

        console.log ("response : " + JSON.stringify(response));
        this.spinnerService.hide();

              let responseBlob = await response.blob();

              console.log("responseBlob : " + JSON.stringify(responseBlob));

              const reader = new FileReader();

              reader.addEventListener('loadend', (e) => {

                //var responseJSON = ;


                try {

                  console.log ("payment detail reader.result : " + reader.result);

                  if (reader.result == "invalid token...") {
                    callback ("invalidToken");
                    return;
                  }

                  let jsonData = JSON.parse (reader.result  as string);

                  console.log ("jsonData : " + jsonData)

                  callback (jsonData, "ok");




                  //responseJSON = JSON.parse (reader.result)
                } catch (e) {
                  console.log ("jsonParseError : " + e);

                  //alert ("The following error occured. Please try again\n\njsonParseError : " + e);

                  callback ("error");

                  //callback(e, []);
                  return;
                }

                return null;
              });

              reader.readAsText(responseBlob);



  } catch (error) {
      console.log ("getMe error : " + error);
  }

  }




}
