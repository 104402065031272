import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Routes, RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from  '@angular/material/icon';
import { MatSidenavModule } from  '@angular/material/sidenav';
import { MatListModule } from  '@angular/material/list';
import { MatToolbarModule } from  '@angular/material/toolbar';
import { MatCheckboxModule } from  '@angular/material/checkbox';
import { MatDialog} from '@angular/material/dialog'
import { MatDialogModule} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { NgxSpinnerModule } from 'ngx-spinner';

import { QrcodescennerComponent } from './qrcodescenner/qrcodescenner.component';
import { PaymentformComponent } from './paymentform/paymentform.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ConfirmbranchComponent } from './confirmbranch/confirmbranch.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { BankChooserDialog } from './paymentform/BankChooserDialog';

import {MatFormField} from '@angular/material/form-field';
import { BlankComponent } from './blank/blank.component';



// see https://github.com/angular/components/issues/16411
export function patchMatFormField() {
  const patchedFormFieldClass = MatFormField.prototype as any;

  patchedFormFieldClass.updateOutlineGapOriginal = MatFormField.prototype.updateOutlineGap;
  MatFormField.prototype.updateOutlineGap = function () {
    this.updateOutlineGapOriginal();
    const container = this._connectionContainerRef.nativeElement;
    const gapEls = container.querySelectorAll('.mat-form-field-outline-gap');
    gapEls.forEach((gp) => {
      const calculatedGapWidth = +gp.style.width.replace('px', '');
      const gapWidth = calculatedGapWidth / 1.02;

      //console.log ("gapWidth : " + gapWidth);
      gp.style.width = `${gapWidth}px`;
    });
  };
}

/*
var config = {
  apiKey: "AIzaSyBZ7GU5AWbhBDA-4rVczrV3RCiGFnr_8Qo",
  authDomain: "echopay-c70b9.firebaseapp.com",
  databaseURL: "https://echopay-c70b9.firebaseio.com",
  projectId: "echopay-c70b9",
  storageBucket: "echopay-c70b9.appspot.com",
  messagingSenderId: "1074605044656",
  appId: "1:1074605044656:web:01c88a1073f6a0ce4f1c75",
  measurementId: "G-PFDGKC5JL1"
};*/

var config = {
  apiKey: "AIzaSyCVDg6OoqsvNEALKNVuq60wr-AI_5hfTVY",
  authDomain: "echopaydev.firebaseapp.com",
  databaseURL: "https://echopaydev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "echopaydev",
  storageBucket: "echopaydev.appspot.com",
  messagingSenderId: "760150528534",
  appId: "1:760150528534:web:db36f66a884a513de71b37",
  measurementId: "G-TXGMJPD8PD"
};


patchMatFormField();

const routes: Routes = [
  { path: '', redirectTo: '/blank', pathMatch: 'full' },
  {path: 'blank', component: BlankComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    QrcodescennerComponent,
    PaymentformComponent,
    ConfirmbranchComponent,
    ThankyouComponent,
    BankChooserDialog,
    BlankComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatListModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ZXingScannerModule,
    RouterModule.forRoot (routes, { useHash: true })
  ],
  exports: [
    MatExpansionModule
  ],
  providers: [CurrencyPipe],
  bootstrap: [AppComponent]
})



export class AppModule {



  ngOnInit() {
    console.log ("bahahahah");
  }



}
