import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Constants from '../../shared/Constants'
import APIManager from '../../shared/APIManager'

import JSConfetti from 'js-confetti'


@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  errorCode = ""

  errorEmoji = ""

  errorLongText = "Payment complete! Thank you for using EchoPay."



  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.errorCode = localStorage.getItem("error_code");
    //this.errorCode = "SUCCESS";
    console.log ("errorCode : " + this.errorCode);

    let apiManager = new APIManager ();

    let errorStrs = apiManager.getErrorCodeReaction (this.errorCode)

    console.log ("errorStrs : " + errorStrs);
    let appTextStr = errorStrs[0];
    let reasonStr = errorStrs[1];
    let emojiStr = errorStrs[2];
    let LongTextStr = errorStrs[3];

    console.log ("appTextStr : " + appTextStr);
    console.log ("reasonStr : " + reasonStr);
    console.log ("emojiStr : " + emojiStr);
    console.log ("appTextStr : " + appTextStr);

    this.errorEmoji = emojiStr;


    if (this.errorCode == "SUCCESS") {
      this.errorLongText = "Payment complete! Thank you for using EchoPay."
      this.surprise ()
    } else {
      this.errorLongText = reasonStr + ". " + LongTextStr;
    }


    //error_code

  }

  public surprise(): void {

    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti({
    confettiColors: [
    '#ff8d800', '#000000', '#ffd800', '#000000', '#ffd800', '#000000',
    ],
    })


  }



  makeAnotherPayment () {
    console.log ("makeAnotherPayment");
    this.router.navigate(['/']);
  }

}
