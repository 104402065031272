import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QrcodescennerComponent } from './qrcodescenner/qrcodescenner.component';
import { PaymentformComponent } from './paymentform/paymentform.component';

import { ConfirmbranchComponent } from './confirmbranch/confirmbranch.component';

import { ThankyouComponent } from './thankyou/thankyou.component';

import { BlankComponent } from './blank/blank.component';


const routes: Routes = [
  { path: 'pay', component: PaymentformComponent },
  { path: 'qrcodescanner', component: QrcodescennerComponent },
  { path: 'confirmbranch', component: ConfirmbranchComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'blank', component: BlankComponent },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
