import { Injectable, NgZone } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
  })


export class YapilyService {

  userData: any; // Save logged in user data

  static epUuid: string;

  constructor(
    private sessionService: SessionService,
  ) {

  }

  setEPUuid (theUuid) {
    this.sessionService.setSessionData("EpUuid", theUuid)
  }

  getEPUuid () {
    return this.sessionService.getSessionData ("EpUuid");
  }

}
